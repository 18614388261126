import * as React from 'react'
import { Placement } from 'popper.js'
import { PopoverComponent } from 'components/PopoverComponent/PopoverComponent'
import { AHIcon } from 'components/Icons/AHIcon/AHIcon'
import { Menu } from 'components/Menu/Menu'
import { EmojiData } from 'emoji-mart'
import { NewEmojiPicker } from 'components/NewEmojiPicker/NewEmojiPicker'
import NewLinkShortener from 'components/NewLinkShortener/NewLinkShortener'
import { ImagePicker } from 'components/ImagePicker/ImagePicker'
import cls from 'classnames'
import { InstitutionAttributeSelector } from 'components/AttributeSelector/AttributeSelector'
import { IInstitutionAttribute } from 'store/personalization/institutionAttributes/selectors'
import {
  IInstitutionAttributeDraftEditor,
  IContactAttributeDraftEditor,
} from 'store/knowledgeSeeder/reducer'
import Tooltip from 'components/Tooltip/Tooltip'
import { ContactAttributeSelector } from 'components/ContactAttributeSelector/ContactAttributeSelector'
import { ContainerButton } from 'components/ContainerButton/ContainerButton'
import { AIIndicator, GenAIResponseGenerator } from 'components/AIMenu/AIMenu'
import 'components/ContextMenu/ContextMenu.scss'
import 'components/NestedDropdown/NestedDropdown.scss'
import { useFeatures } from 'util/hooks'
import { EventAction } from 'components/EventTracker/EventTracker'

export interface IContextMenuGenAIProps {
  summaryQuestion: string
  understandingId: string
  onSelectGenAIAnswer: (response: string) => void
}

interface IContextMenuProps {
  disabled?: boolean
  popoverPlacement: Placement
  flags: IContextMenuFlags
  onPickEmoji: (
    emojiData: EmojiData,
    event: React.MouseEvent<HTMLElement>
  ) => void
  onLinkShorten: (shortLink: string) => void
  onPickImage?: (imageLink: string, name?: string, contentType?: string) => void
  onPickInstitutionAttribute?: (attribute: IInstitutionAttribute) => void
  onPickContactAttribute?: (attribute: IContactAttributeDraftEditor) => void
  contextOptions?: React.ReactNode
  includeSensitiveContactAttributess: boolean
  fireFlyEventTrackerObj?: {
    eventLocation?: string
    eventAction?: EventAction
    eventObject?: string
  }
  genAIProps?: IContextMenuGenAIProps
}

export interface IContextMenuFlags {
  image?: boolean
  emoji?: boolean
  link?: boolean
  context?: boolean
  institutionAttributeSelector?: boolean
  contactAttributeSelector?: boolean
  genAIFireFly?: boolean
}

interface IContextMenuState {
  visible: boolean
  menuType: PopperType | undefined
  disableGenAIButton: boolean
}

enum PopperType {
  context,
  link,
  emoji,
  image,
  institutionAttributeSelector,
  contactAttributeSelector,
  genAIFireFly,
}

export const ContextMenu = (props: IContextMenuProps) => {
  const [state, setState] = React.useState<IContextMenuState>({
    visible: false,
    menuType: undefined,
    disableGenAIButton: false,
  })

  const { hasFeature, FeaturesType } = useFeatures()

  const handleButtonClick = (e: React.MouseEvent, type: PopperType) => {
    e.preventDefault()
    if (state.visible) {
      closeMenu()
    } else {
      setState({
        ...state,
        visible: true,
        menuType: type,
        disableGenAIButton: type !== PopperType.genAIFireFly,
      })
    }
  }

  const handleContextClick = (e: React.MouseEvent) => {
    handleButtonClick(e, PopperType.context)
  }

  const handleLinkClick = (e: React.MouseEvent) => {
    handleButtonClick(e, PopperType.link)
  }

  const handleEmojiClick = (e: React.MouseEvent) => {
    handleButtonClick(e, PopperType.emoji)
  }

  const handleImageClick = (e: React.MouseEvent) => {
    handleButtonClick(e, PopperType.image)
  }

  const handleAttributeSelectorClick = (e: React.MouseEvent) => {
    handleButtonClick(e, PopperType.institutionAttributeSelector)
  }

  const handleContactAttributeSelectorClick = (e: React.MouseEvent) => {
    handleButtonClick(e, PopperType.contactAttributeSelector)
  }

  const handleGenAIFireFlyClick = (e: React.MouseEvent) => {
    handleButtonClick(e, PopperType.genAIFireFly)
  }

  const onPickImage = (
    imageLink: string,
    name?: string,
    contentType?: string
  ) => {
    props.onPickImage?.(imageLink, name, contentType)
    closeMenu()
  }

  const onLinkShorten = (shortLink: string) => {
    props.onLinkShorten(shortLink)
    closeMenu()
  }

  const onAttributeSelect = (attribute: IInstitutionAttributeDraftEditor) => {
    props.onPickInstitutionAttribute?.(attribute)
    closeMenu()
  }

  const onContactAttributeSelect = (
    attribute: IContactAttributeDraftEditor
  ) => {
    props.onPickContactAttribute?.(attribute)
    closeMenu()
  }

  const onPickEmoji = (
    emojiData: EmojiData,
    event: React.MouseEvent<HTMLElement>
  ) => {
    props.onPickEmoji(emojiData, event)
    closeMenu()
  }

  const closeMenu = () => {
    setState({
      visible: false,
      menuType: undefined,
      disableGenAIButton: false,
    })
  }

  const renderIconStyle = (type: PopperType) => {
    return cls(
      type === state.menuType ? 'text-info' : 'text-mainstay-dark-blue-65',
      'h4',
      'pl-1 pr-1',
      'margin-right-2px',
      'pointer'
    )
  }

  const renderIcons = () => {
    const {
      image,
      emoji,
      link,
      context,
      institutionAttributeSelector,
      contactAttributeSelector,
    } = props.flags || {
      image: undefined,
      emoji: undefined,
      link: undefined,
      context: undefined,
    }
    return (
      <>
        {institutionAttributeSelector && (
          <Tooltip
            isEnabled={institutionAttributeSelector}
            content="Organization Attributes">
            <ContainerButton onClick={handleAttributeSelectorClick}>
              <AHIcon
                name="attribute"
                className={cls(
                  renderIconStyle(PopperType.institutionAttributeSelector),
                  'pl-1'
                )}
              />
            </ContainerButton>
          </Tooltip>
        )}
        {contactAttributeSelector && (
          <Tooltip
            isEnabled={contactAttributeSelector}
            content="Contact Fields">
            <ContainerButton onClick={handleContactAttributeSelectorClick}>
              <AHIcon
                name="person"
                className={renderIconStyle(PopperType.contactAttributeSelector)}
              />
            </ContainerButton>
          </Tooltip>
        )}
        {props.contextOptions && context && (
          <ContainerButton onClick={handleContextClick}>
            <AHIcon
              name="add"
              className={renderIconStyle(PopperType.context)}
            />
          </ContainerButton>
        )}
        {link && (
          <ContainerButton onClick={handleLinkClick}>
            <AHIcon name="link" className={renderIconStyle(PopperType.link)} />
          </ContainerButton>
        )}
        {emoji && (
          <ContainerButton onClick={handleEmojiClick}>
            <AHIcon
              name="tag_faces"
              className={renderIconStyle(PopperType.emoji)}
            />
          </ContainerButton>
        )}
        {image && (
          <ContainerButton onClick={handleImageClick}>
            <AHIcon
              name="image"
              className={renderIconStyle(PopperType.image)}
            />
          </ContainerButton>
        )}
        {hasFeature(FeaturesType.GENERATIVE_AI_KNOWLEDGE_BASE_ANSWERS) &&
          props.genAIProps && (
            <ContainerButton
              className="mt-1"
              disabled={state.disableGenAIButton}
              onClick={handleGenAIFireFlyClick}>
              <AIIndicator />
            </ContainerButton>
          )}
      </>
    )
  }

  return (
    <PopoverComponent
      className="popover-box"
      visible={state.visible && !props.disabled}
      popoverPlacement={props.popoverPlacement}
      onClickOutside={closeMenu}
      renderReference={renderIcons}
      tabIndex={0}
      modifiers={{
        preventOverflow: {
          padding: 0,
        },
        offset: { offset: 0 },
      }}
      renderPopper={() => {
        return (
          <ContextPopover
            onPickEmoji={onPickEmoji}
            onLinkShorten={onLinkShorten}
            onPickImage={onPickImage}
            popperType={state.menuType}
            contextOptions={props.contextOptions}
            onClickInstitutionAttributeSelector={onAttributeSelect}
            onClickContactAttributeSelector={onContactAttributeSelect}
            onClose={closeMenu}
            includeSensitiveContactAttributes={
              props.includeSensitiveContactAttributess
            }
            genAIProps={props.genAIProps}
          />
        )
      }}
    />
  )
}

export class ContextPopover extends React.PureComponent<{
  readonly popperType: PopperType | undefined
  readonly onPickEmoji: (
    emojiData: EmojiData,
    event: React.MouseEvent<HTMLElement>
  ) => void
  readonly onLinkShorten: (shortLink: string) => void
  readonly onPickImage: (imageLink: string, name?: string) => void
  readonly onClickInstitutionAttributeSelector: (
    attr: IInstitutionAttribute
  ) => void
  readonly onClickContactAttributeSelector: (
    attr: IContactAttributeDraftEditor
  ) => void
  readonly onClose: () => void
  readonly contextOptions: React.ReactNode | undefined
  readonly includeSensitiveContactAttributes: boolean
  readonly genAIProps?: IContextMenuGenAIProps
}> {
  render() {
    const {
      popperType,
      contextOptions,
      onPickEmoji,
      onLinkShorten,
      onPickImage,
      onClickContactAttributeSelector,
      onClickInstitutionAttributeSelector,
      onClose,
      includeSensitiveContactAttributes,
      genAIProps,
    } = this.props
    switch (popperType) {
      case PopperType.context:
        return <Menu>{contextOptions}</Menu>
      case PopperType.link:
        return <NewLinkShortener onGenerateShortLink={onLinkShorten} />
      case PopperType.emoji:
        return <NewEmojiPicker onPickEmoji={onPickEmoji} />
      case PopperType.image:
        return <ImagePicker onPickImage={onPickImage} />
      case PopperType.institutionAttributeSelector:
        return (
          <InstitutionAttributeSelector
            onClose={onClose}
            onSelectAttribute={onClickInstitutionAttributeSelector}
          />
        )
      case PopperType.contactAttributeSelector:
        return (
          <ContactAttributeSelector
            onClose={onClose}
            onSelectAttribute={onClickContactAttributeSelector}
            includeSensitiveContactAttributes={
              includeSensitiveContactAttributes
            }
          />
        )
      case PopperType.genAIFireFly:
        return genAIProps ? (
          <GenAIResponseGenerator
            understandingId={genAIProps.understandingId}
            summaryQuestion={genAIProps.summaryQuestion}
            onSelect={genAIProps.onSelectGenAIAnswer}
            onCancel={onClose}
          />
        ) : null
    }
    return null
  }
}
