import { RootState as IState } from 'store/store'
import { isLoading } from 'store/webdata'
import * as Raven from '@sentry/browser'

export enum ContactAttributeType {
  TEXT = 'TEXT',
  DATE = 'DATE',
  PHONE = 'PHONE',
  EMAIL = 'EMAIL',
  URL = 'URL',
  NUMBER = 'NUMBER',
  MULTI_CHOICE = 'MULTI_CHOICE',
  BOOLEAN = 'BOOLEAN',
  JSON_STRING_ARRAY = 'JSON_STRING_ARRAY',
}

export interface IContactField {
  field: string
  readableName?: string
  description?: string | null
  type: ContactAttributeType
  requires_auth?: boolean
  include_in_escalations?: boolean
  options?: IContactAttributeOption[]
  id?: number
}

export interface IContactAttribute extends IContactField {
  id: number
  requires_auth: boolean
  include_in_escalations: boolean
  onAttrRowClick?: (attrId: number) => void
  topLevelField?: boolean
  referencesCount?: number
}

export interface IMultiChoiceContactAttribute extends IContactAttribute {
  options: IContactAttributeOption[]
}

export const MESSAGING_STATUS_FIELD_PREFIX = 'messaging_status_'
export const MESSAGING_STATUS_READABLE_NAME_PREFIX = 'Messaging Status ('

export const MessagingStatusEnum = {
  OptedIn: 'OptedIn',
  Paused: 'Paused',
  Stopped: 'Stopped',
} as const

export const humanizedMessagingStatuses: {
  [key in keyof typeof MessagingStatusEnum]: string
} = {
  [MessagingStatusEnum.OptedIn]: 'Opted In',
  [MessagingStatusEnum.Paused]: 'Paused',
  [MessagingStatusEnum.Stopped]: 'Opted Out',
}

export type MessagingStatus = typeof MessagingStatusEnum[keyof typeof MessagingStatusEnum]

export const getHumanizedMessagingStatus = (
  status: keyof typeof MessagingStatusEnum
): string => {
  const humanized = humanizedMessagingStatuses[status]
  if (!humanized) {
    Raven.captureException(
      `Unable to identify humanized messaging status: ${status}`
    )
  }
  return humanized
}

export const isContactAttribute = (
  contactField?: IContactField
): contactField is IContactAttribute =>
  contactField !== undefined &&
  'id' in contactField &&
  typeof contactField['id'] === 'number'

export const isMessagingStatusField = (contactField: IContactField) =>
  contactField.field.startsWith(MESSAGING_STATUS_FIELD_PREFIX) &&
  (contactField.readableName ?? '').startsWith(
    MESSAGING_STATUS_READABLE_NAME_PREFIX
  )

export const isMultiChoiceField = (
  attribute: IContactField
): attribute is IMultiChoiceContactAttribute => {
  return (
    (!!attribute.options &&
      attribute.type === ContactAttributeType.MULTI_CHOICE) ||
    // `options` for the `transport` and `messaging_status_` fields don't come from the backend
    attribute.field === 'transport' ||
    isMessagingStatusField(attribute)
  )
}

export interface IContactAttributeOption {
  id: number
  value: string
  order?: number
}

export interface IContactAttributes extends Array<IContactAttribute> {}
export interface ITopLevelContactFields extends Array<IContactField> {}

export interface IContactAttributeValue {
  readonly contactAttribute: number
  readonly attributeName: string
  readonly attributeType: ContactAttributeType
  readonly attributeDescription: string | null
  readonly id: number
  readonly mongoContactId: string
  readonly value: string
}

export interface IContactAttributeValues
  extends Array<IContactAttributeValue> {}

export const getContactAttributes = (state: IState) => {
  return state.contactsPersonalization.contactAttributes
}
export const getContactAttributesAreLoading = (state: IState) =>
  isLoading(state.contactsPersonalization.contactAttributes)

export const getCountContactAttributes = (state: IState) =>
  state.contactsPersonalization.countContactAttributes

export const getAllContactAttributes = (state: IState) => {
  return state.contactsPersonalization.allContactAttributes
}
export const getContactAttributesRes = (state: IState) => {
  return state.contactsPersonalization.contactAttributesRes
}
export const getScriptyContactAttributesRes = (state: IState) => {
  return state.contactsPersonalization.scriptyContactAttributesRes
}
export const getSaveableTopLevelFieldsRes = (state: IState) => {
  return state.contactsPersonalization.saveableTopLevelFieldsRes
}

export const getTopLevelContactFields = (state: IState) => {
  return state.contactsPersonalization.topLevelContactFields
}

export interface IAttributeRef {
  type:
    | 'understanding'
    | 'dialog'
    | 'escalation_rule'
    | 'contact_filter'
    | 'unknown'
  id: string
  sample_question: string | null
  human_name: string | null
}
