import { ICampaignScriptStateUpdateRequest } from 'api/request'
import AddNodeMenuButton from 'components/AddNodeButton/AddNodeButton'
import CampaignBuilderContextMenu from 'components/CampaignBuilderContextMenu/CampaignBuilderContextMenu'
import DeleteNodeButton from 'components/DeleteNodeButton/DeleteNodeButton'
import DraftEditor from 'components/DraftEditor/DraftEditor'
import {
  CONTACT_ATTRIBUTE_TEMPLATE_REGEX,
  TOPLEVEL_TEMPLATE_REGEX,
} from 'components/DraftEditor/draftUtils'
import EditDisplayComponent from 'components/EditDisplayComponent/EditDisplayComponent'
import { EditableFallbackMessage } from 'components/FallbackMessage/EditableFallbackMessage'
import { DEFAULT_DIALOG_STATE_MESSAGE } from 'const/settings'
import { Form, Formik } from 'formik'
import * as React from 'react'
import {
  ICampaignScriptStep,
  IExitAction,
  PromptType,
} from 'store/campaign-scripts/reducer'
import * as yup from 'yup'
import { Dropdown } from 'components/Dropdown/Dropdown'
import classNames from 'classnames'
import 'components/EditableOutgoingNode/EditableOutgoingNode.scss'
import Tooltip from 'components/Tooltip/Tooltip'
import { WarningIcon } from 'mainstay-ui-kit/MainstayToast/Icons/WarningIcon/WarningIcon'

interface IEditablePromptProps {
  node: ICampaignScriptStep
  value: string
  personalizedPrompt?: string | null
  editing?: boolean
  editable?: boolean
  onEditNode?: (editing: boolean) => void
  onChange?: (request: ICampaignScriptStateUpdateRequest) => Promise<void>
  onDelete?: () => void
  onChangeImage?: (value: string, name?: string, contentType?: string) => void
  onSetPauseTime?: () => void
  onClearPauseTime?: () => void
  onOptOut?: () => void
  onRemoveMedia?: () => void
  createBlankExitAction?: (action: IExitAction) => void
  isTerminal: boolean
  onClickAddLink?: () => void
  onClickAddChild?: (type: PromptType) => void
  menuType?: 'full' | 'limited'
  setValueBeingSaved?: (newMessage: string | null) => void
  handlePromptChange?: () => void
  isExcessive?: boolean
}

const validationSchema = yup.object().shape({
  message: yup
    .string()
    .required('Message is required.')
    .test(
      'lodash template',
      'Template variables in the form <% templateVariableText %> are no longer supported',
      (value: string) => {
        return !/<%.*%>/.test(value)
      }
    ),
  actions: yup.array(
    yup.object().shape({
      email: yup.string().when('autoSelect', {
        is: false,
        then: yup.string().required('Email is a required field.'),
      }),
      subject: yup.string().required('Subject is a required field.'),
      note: yup.string(),
      autoSelect: yup.bool(),
    })
  ),
})
interface IDropdownItem {
  label: string
  value: string
}

export const hasContactFields = (prompt?: string | null) => {
  if (!prompt) {
    return false
  }
  return (
    TOPLEVEL_TEMPLATE_REGEX.test(prompt) ||
    CONTACT_ATTRIBUTE_TEMPLATE_REGEX.test(prompt)
  )
}
const PromptItems = [
  { value: 'yes/no', label: 'Yes/No' },
  { value: 'mcq', label: 'Multiple Choice' },
]

export class EditableOutgoingNode extends React.PureComponent<
  IEditablePromptProps,
  {
    editing: boolean
    renderValidation: boolean
    menuVisible: boolean
    content: string
    personalizedContent?: string | null
    personalizedPromptError?: string
    hasContactAttributes: boolean
    messageTypeDropdown: IDropdownItem
  }
> {
  constructor(props: IEditablePromptProps) {
    super(props)
    this.state = {
      editing: !!props.node.editing,
      renderValidation: false,
      menuVisible: false,
      content: props.value,
      personalizedContent: props.personalizedPrompt,
      personalizedPromptError: undefined,
      hasContactAttributes: hasContactFields(props.personalizedPrompt),
      messageTypeDropdown: {
        label: 'Yes/No',
        value: 'yes/no',
      },
    }
  }

  handleEdit = (editing: boolean) => {
    this.setState({ editing })
    this.props.onEditNode?.(editing)
  }

  componentDidUpdate = (prevProps: IEditablePromptProps) => {
    if (prevProps.value !== this.props.value) {
      this.handleEdit(false)
    }
  }

  handleChangeImage = (value: string, name?: string, contentType?: string) => {
    this.props.onChangeImage?.(value, name, contentType)
  }

  handleDelete = (e: React.MouseEvent) => {
    e.preventDefault()
    if (this.props.onDelete !== undefined) {
      this.props.onDelete()
    }
  }

  handleToggleMenu = () => {
    this.setState(s => ({ menuVisible: !s.menuVisible }))
  }

  createBlankExitAction = (action: IExitAction) => {
    if (this.props.createBlankExitAction) {
      this.props.createBlankExitAction(action)
    }
  }
  handleMessageTypeDropdown = (e: IDropdownItem) => {
    this.props.handlePromptChange?.()
    this.setState({ messageTypeDropdown: e })
  }

  render() {
    if (!this.props.editable || !this.props.onChange) {
      return this.props.children
    }
    return (
      <div className="d-flex flex-column">
        <Formik<{ readonly message: string; readonly fallbackMessage?: string }>
          enableReinitialize
          initialValues={{
            message: this.state.personalizedContent || this.state.content,
            fallbackMessage: this.state.personalizedContent
              ? this.state.content
              : undefined,
          }}
          validationSchema={validationSchema}
          onSubmit={values => {
            if (this.props.onChange && this.state.editing) {
              const newMessage = values.fallbackMessage || values.message
              this.props.setValueBeingSaved?.(newMessage)
              this.setState({
                content: newMessage,
                personalizedContent: values.fallbackMessage
                  ? values.message
                  : undefined,
              })
              this.props
                .onChange({
                  dialogId: this.props.node.parentDialog,
                  dialogStateId: this.props.node.id,
                  data: {
                    prompt: newMessage,
                    personalizedPrompt: values.fallbackMessage
                      ? values.message
                      : undefined,
                  },
                })
                .then(() => {
                  this.props.setValueBeingSaved?.(null)
                  this.handleEdit(false)
                })
            }
          }}
          render={formProps => {
            // HACK(sbdchd): Ideally we'd have an empty string for
            // the node's default value, but due to a quirk of us
            // creating the nodes on the server when you press
            // "add node" and the database requiring the text be
            // non-empty, we're stuck with this approach.
            if (formProps.values.message === DEFAULT_DIALOG_STATE_MESSAGE) {
              formProps.setValues({ message: '' })
              this.setState({
                content: '',
              })
            }
            if (
              formProps.values.fallbackMessage === DEFAULT_DIALOG_STATE_MESSAGE
            ) {
              formProps.setValues({
                message: formProps.values.message,
                fallbackMessage: '',
              })
              this.setState({
                content: '',
              })
            }
            return (
              <Form className="d-flex flex-column">
                <div className="d-flex align-items-top editable-outgoing-container w-100 ">
                  <div className="node w-100">
                    <EditDisplayComponent
                      onClick={e => {
                        e?.stopPropagation()
                        this.handleEdit(true)
                      }}
                      onClickOutside={() => {
                        if (
                          hasContactFields(formProps.values.message) &&
                          !formProps.values.fallbackMessage?.trim()
                        ) {
                          this.setState({
                            renderValidation: true,
                            personalizedPromptError:
                              'Fallback message is required.',
                          })
                          return
                        }
                        this.setState({ renderValidation: true })
                        formProps.submitForm()
                      }}
                      editing={this.state.editing}
                      editor={
                        <div className="script-editor-container">
                          <div
                            className={classNames('shadow-border rounded', {
                              'border-danger bw-1px edit-script-container-error': this
                                .state.renderValidation
                                ? (this.state.personalizedContent !==
                                    undefined &&
                                    !!this.state.personalizedPromptError) ||
                                  formProps?.errors?.message !== undefined
                                : false,
                            })}>
                            <div className="edit-script-node-header pl-2 py-1 d-flex justify-content-between align-content-start align-items-center">
                              {this.props.node.promptType === 'Boolean' ? (
                                <div className="edit-script-node-dropdown">
                                  <Dropdown
                                    onSelect={this.handleMessageTypeDropdown}
                                    items={PromptItems}
                                    value={this.state.messageTypeDropdown}
                                    overrideWidth=""
                                  />
                                </div>
                              ) : (
                                <div className="edit-script-type">
                                  {this.props.node.promptType === 'Number'
                                    ? 'Multiple Choice'
                                    : this.props.node.promptType === 'Open'
                                    ? 'Open Response'
                                    : null}
                                </div>
                              )}
                              <DeleteNodeButton onClick={this.handleDelete} />
                            </div>
                            <DraftEditor
                              error={
                                this.state.renderValidation
                                  ? formProps?.errors?.message
                                  : undefined
                              }
                              autoFocus={true}
                              containerClassName="script-editor"
                              onFocusClassName=""
                              onErrorClassName=""
                              onErrorTextClassName="text-danger draft-error py-1 px-2"
                              hasContactAttributes={
                                this.state.hasContactAttributes
                              }
                              initialAnswer={
                                this.state.personalizedContent ||
                                this.state.content
                              }
                              onChange={value => {
                                // DraftEditor fires an onChange when it opens. We want this to be a no-op, instead
                                // of inferring that there is no content, and thus no personalized content.
                                if (!value && !!this.state.content) {
                                  return
                                }
                                if (this.state.personalizedContent) {
                                  formProps.handleChange({
                                    target: {
                                      name: 'message',
                                      value,
                                    },
                                  })
                                  if (!hasContactFields(value)) {
                                    this.setState({
                                      content: value,
                                      personalizedContent: null,
                                      hasContactAttributes: false,
                                    })
                                    formProps.setFieldValue(
                                      'fallbackMessage',
                                      ''
                                    )
                                  }
                                } else {
                                  formProps.handleChange({
                                    target: {
                                      name: 'message',
                                      value,
                                    },
                                  })
                                  formProps.setFieldValue('fallbackMessage', '')
                                  if (hasContactFields(value)) {
                                    this.setState({
                                      personalizedContent: value,
                                      content: '',
                                      hasContactAttributes: true,
                                    })
                                  }
                                }
                              }}
                              onPickImage={(
                                value: string,
                                name?: string,
                                contentType?: string
                              ) => {
                                this.setState({
                                  content:
                                    formProps.values.fallbackMessage ||
                                    formProps.values.message,
                                  personalizedContent: formProps.values
                                    .fallbackMessage
                                    ? formProps.values.message
                                    : undefined,
                                })
                                this.handleChangeImage(value, name, contentType)
                              }}
                              textBoxLabel=""
                              toggleResetDraftEditor={false}
                              disableInstitutionAttributes={false}
                              disableContactAttributes={false}
                              includeSensitiveContactAttributes={false}
                            />
                            {this.state.personalizedContent && (
                              <EditableFallbackMessage
                                error={this.state.personalizedPromptError}
                                onChange={value => {
                                  this.setState({
                                    personalizedPromptError: undefined,
                                  })
                                  formProps.handleChange({
                                    target: {
                                      name: 'fallbackMessage',
                                      value,
                                    },
                                  })
                                }}
                                content={this.state.content}
                              />
                            )}
                          </div>
                        </div>
                      }
                      display={this.props.children}
                    />
                  </div>
                  {this.props.editable && !this.state.editing && (
                    <AddNodeMenuButton
                      onClick={this.handleToggleMenu}
                      className={classNames(
                        'node-add-button',
                        this.props.node.promptType === PromptType.number
                          ? 'pt-3'
                          : 'pt-2'
                      )}
                    />
                  )}
                  {this.props.isExcessive && (
                    <div className="pt-1">
                      <Tooltip content="Sending too many messages in quick succession is not advised. We recommend consolidating these wherever possible.">
                        <div>
                          <WarningIcon fill="#FEC84B" />
                        </div>
                      </Tooltip>
                    </div>
                  )}
                </div>
                {this.state.menuVisible && (
                  <div className="mt-1 align-self-start">
                    <CampaignBuilderContextMenu
                      onClickAddLink={this.props.onClickAddLink}
                      onClickAdd={this.props.onClickAddChild}
                      nodeType={this.props.node.promptType}
                      node={this.props.node}
                      menuType={this.props.menuType}
                      onClose={this.handleToggleMenu}
                    />
                  </div>
                )}
              </Form>
            )
          }}
        />
      </div>
    )
  }
}
