import { RootState as IState } from 'store/store'
import {
  IInstitutionAttributeDraftEditor,
  IContactAttributeDraftEditor,
} from 'store/knowledgeSeeder/reducer'
import {
  ContactAttributeType,
  IContactAttribute,
  IContactField,
} from 'store/personalization/contactAttributes/selectors'
import {
  Failure,
  Success,
  isLoading,
  Loading,
  isSuccess,
  isFailure,
} from 'store/webdata'
import { IInstitutionAttribute } from 'store/personalization/institutionAttributes/selectors'

export const getAttributeByIdMapping = (
  state: IState,
  fromScriptLibrary = false
) => {
  const institutionAttrsReducer = (
    map: {
      [id: number]: IInstitutionAttributeDraftEditor
    },
    attribute: IInstitutionAttribute
  ) => {
    map[attribute.id] = {
      id: attribute.id,
      field: attribute.field,
      type: attribute.type,
      value: attribute.value,
    }
    return map
  }
  const contactAttrsReducer = (
    map: {
      [id: number]: IContactAttributeDraftEditor
    },
    attribute: IContactAttribute
  ) => {
    map[attribute.id] = {
      id: attribute.id,
      field: attribute.field,
      type: attribute.type,
    }
    return map
  }

  const topLevelFieldReducer = (
    map: {
      [id: string]: {
        field: string
        type: ContactAttributeType
      }
    },
    topLevelField: IContactField
  ) => {
    map[topLevelField.field] = {
      field: topLevelField.field,
      type: topLevelField.type,
    }
    return map
  }

  const stateInstitutionAttrs = fromScriptLibrary
    ? state.personalization.allScriptyInstitutionAttributes
    : state.personalization.allInstitutionAttributes
  const stateContactAttrs = fromScriptLibrary
    ? state.contactsPersonalization.allScriptyContactAttributes
    : state.contactsPersonalization.allContactAttributes

  if (
    isLoading(stateInstitutionAttrs) ||
    isLoading(stateContactAttrs) ||
    isLoading(state.contactsPersonalization.topLevelContactFields)
  ) {
    return Loading()
  }

  if (
    isSuccess(stateInstitutionAttrs) &&
    isSuccess(stateContactAttrs) &&
    isSuccess(state.contactsPersonalization.topLevelContactFields)
  ) {
    return Success({
      institution: stateInstitutionAttrs.data.reduce(
        institutionAttrsReducer,
        {}
      ),
      contact: stateContactAttrs.data.reduce(contactAttrsReducer, {}),
      toplevel: state.contactsPersonalization.topLevelContactFields.data.reduce(
        topLevelFieldReducer,
        {}
      ),
    })
  }

  if (
    isFailure(stateInstitutionAttrs) ||
    isFailure(stateContactAttrs) ||
    isFailure(state.contactsPersonalization.topLevelContactFields)
  ) {
    return Failure({
      institution: {},
      contact: {},
      toplevel: {},
    })
  }
}
