import * as React from 'react'
import { InstitutionAttributeType } from 'store/personalization/institutionAttributes/selectors'
import { withStyles, Theme } from '@material-ui/core/styles'
import { Tooltip } from 'components/MaterialComponents/MaterialComponents'
import { AHIcon, AHIconType } from 'components/Icons/AHIcon/AHIcon'
import { formatPhone } from 'util/phone'
import { ATTRIBUTES, CONTACTS } from 'const/routes'
import { Link } from 'util/routing'
import { AttributeKind } from 'components/DraftEditor/DraftEditor'
import { IAttributeEntityShort } from 'components/DraftEditor/draftUtils'
import cls from 'classnames'

const StyledAttributeCard = ({
  id,
  value,
  field,
  link,
  icon,
  attributeTextContent,
  showToolTip = false,
}: {
  id: number | string
  value: string | number | Date | undefined
  field: string
  link?: string
  icon: AHIconType
  showToolTip: boolean
  attributeTextContent: JSX.Element
}) => {
  const StyledToolTip = withStyles((theme: Theme) => ({
    tooltip: {
      background: '#fff',
      backgroundColor: '#fff',
      color: 'rgba(0, 0, 0, 0.87)',
      fontSize: theme.typography.pxToRem(14),
      border: '1px solid #dadde9',
      padding: '10px',
      width: 'fit-content',
      borderRadius: 2,
    },
  }))(Tooltip)

  return (
    <>
      {showToolTip && id ? (
        <StyledToolTip
          interactive
          title={
            <div className="d-flex justify-content-between align-items-center">
              <div className="d-flex flex-column text-truncate">
                <div className="d-flex text-muted">
                  <AHIcon name={icon} />
                  <div>{field}</div>
                </div>
                {value && <div className="ml-1 text-truncate">{value}</div>}
              </div>
              {link && (
                <Link to={`${link}?id=${id}`} target="_blank">
                  <AHIcon
                    name="open_in_new"
                    className="large text-muted new-page-link"
                  />
                </Link>
              )}
            </div>
          }
          placement="top">
          {attributeTextContent}
        </StyledToolTip>
      ) : (
        attributeTextContent
      )}
    </>
  )
}

interface IAttributeBlockProps {
  attribute?: IAttributeEntityShort
  children: React.ReactNode
  showToolTip?: boolean
  className?: string
  iconClassName?: string
}

export const AttributeBlock = ({
  attribute,
  children,
  showToolTip = true,
  className,
  iconClassName,
}: IAttributeBlockProps) => {
  const isInstitution = attribute?.kind === AttributeKind.INSTITUTION
  const attributeIcon = isInstitution ? 'attribute' : 'person'
  const attributeTextContent = (
    <span
      className={cls(
        className,
        'rounded px-1',
        { 'attribute-institution': isInstitution },
        { 'attribute-contact': !isInstitution }
      )}>
      <AHIcon
        name={attributeIcon}
        className={iconClassName}
        style={{ transform: 'translateY(1px)' }}
      />
      {children}
    </span>
  )
  if (!attribute || !attribute?.kind) {
    return <>{attributeTextContent}</>
  }
  const { id, field } = attribute
  if (attribute.kind === AttributeKind.CONTACT) {
    return (
      <StyledAttributeCard
        id={id || 0}
        value={undefined}
        link={CONTACTS.PROFILE_FIELDS}
        field={field}
        icon={attributeIcon}
        attributeTextContent={attributeTextContent}
        showToolTip={showToolTip}
      />
    )
  }
  if (attribute.kind === AttributeKind.TOPLEVEL) {
    return (
      <StyledAttributeCard
        id={id || 0}
        value={undefined}
        link={undefined}
        field={field}
        icon={attributeIcon}
        attributeTextContent={attributeTextContent}
        showToolTip={showToolTip}
      />
    )
  }
  if (attribute.kind === AttributeKind.INSTITUTION) {
    const value =
      attribute.type === InstitutionAttributeType.PHONE
        ? formatPhone(String(attribute.value))
        : attribute.value
    return (
      <StyledAttributeCard
        id={id || 0}
        value={value || ''}
        link={ATTRIBUTES.INDEX}
        field={field}
        icon={attributeIcon}
        attributeTextContent={attributeTextContent}
        showToolTip={showToolTip}
      />
    )
  }
  return <>{attributeTextContent}</>
}
