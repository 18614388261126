import { IMappingAttribute, IAttributeMap } from 'api/response'
import { InstitutionAttributeType } from 'store/personalization/institutionAttributes/selectors'
import { ContactAttributeType } from 'store/personalization/contactAttributes/selectors'

export interface IAttrOption {
  label: string
  value: number
}

export const mapAttrsToOptions = (attrs: IMappingAttribute[]): IAttrOption[] =>
  attrs.map(attr => ({ label: attr.name, value: attr.id }))

export type DataTypes =
  | 'TEXT'
  | 'DATE'
  | 'PHONE'
  | 'NUMBER'
  | 'URL'
  | 'EMAIL'
  | 'MULTI_CHOICE'
  | 'BOOLEAN'
  | 'JSON_STRING_ARRAY'

interface IAttributesByType {
  TEXT: IMappingAttribute[]
  DATE: IMappingAttribute[]
  PHONE: IMappingAttribute[]
  NUMBER: IMappingAttribute[]
  URL: IMappingAttribute[]
  EMAIL: IMappingAttribute[]
  MULTI_CHOICE: IMappingAttribute[]
  BOOLEAN: IMappingAttribute[]
  JSON_STRING_ARRAY: IMappingAttribute[]
}

const targetAttributesByType: IAttributesByType = {
  TEXT: [],
  DATE: [],
  PHONE: [],
  NUMBER: [],
  URL: [],
  EMAIL: [],
  MULTI_CHOICE: [],
  BOOLEAN: [],
  JSON_STRING_ARRAY: [],
}

export const getAttrTypeAsString = (
  attr: IMappingAttribute
): DataTypes | undefined => {
  if (
    Object.keys({
      ...InstitutionAttributeType,
      ...ContactAttributeType,
    }).includes(attr.data_type)
  ) {
    return attr.data_type
  }
  return undefined
}

export const getTargetAttrsByDataType = (targetAttrs: IMappingAttribute[]) => {
  return targetAttrs.reduce(
    (acc: IAttributesByType, elem: IMappingAttribute) => {
      const attrType = getAttrTypeAsString(elem)

      if (attrType) {
        return {
          ...acc,
          [attrType]: Array.from(new Set([...acc[attrType], elem])),
        }
      }
      return acc
    },
    targetAttributesByType
  )
}

export const getOnlyUnmappedSourceAttrs = (
  sourceAttrs: IMappingAttribute[],
  mappings: IAttributeMap[]
): IMappingAttribute[] => {
  return sourceAttrs.filter(
    sourceAttr =>
      !mappings
        .map(mappedPair => mappedPair.source_attribute.id)
        .includes(sourceAttr.id)
  )
}

export const getOnlyUnmappedTargetAttrs = (
  targetAttrs: IMappingAttribute[],
  mappings: IAttributeMap[]
): IMappingAttribute[] => {
  return targetAttrs.filter(
    targetAttr =>
      !mappings
        .map(mappedPair => mappedPair.target_attribute.id)
        .includes(targetAttr.id)
  )
}

export const getMappedTargetAttr = (
  sourceAttr: IMappingAttribute,
  targetAttrs: IMappingAttribute[],
  mappings: IAttributeMap[]
): IMappingAttribute | undefined => {
  const targetAttrId = mappings.find(
    map => map.source_attribute.id === sourceAttr.id
  )?.target_attribute.id
  if (targetAttrId) {
    return targetAttrs.find(attr => attr.id === targetAttrId)
  }
  return undefined
}

export const updateMapping = (
  mappings: IAttributeMap[],
  sourceAttrId: number,
  targetAttrId: number,
  sourceAttrs: IMappingAttribute[],
  targetAttrs: IMappingAttribute[],
  unmap: boolean = false
): IAttributeMap[] => {
  // If unmapping a source attr (by clearing a target attr dropdown),
  // remove the corresponding object from the list of mappings where the
  // source_attribute id matches the sourceAttr to unmap.
  if (unmap) {
    return mappings.filter(
      mappedPair => mappedPair.source_attribute.id !== sourceAttrId
    )
  }

  // Otherwise...
  const updatedMappings = [...mappings]

  let sourceAttrIndex: number | undefined = undefined

  // Find the sourceAttr to map either in the existing mappings or just in the list of sourceAttrs.
  const sourceAttr: IMappingAttribute | undefined =
    updatedMappings.find((map, i) => {
      if (map.source_attribute.id === sourceAttrId) {
        // If this sourceAttr is already mapped, we will need its index in the mappings list.
        sourceAttrIndex = i
        return map.source_attribute
      }
    })?.source_attribute || sourceAttrs.find(attr => attr.id === sourceAttrId)

  const targetAttr = targetAttrs.find(attr => attr.id === targetAttrId)

  // If the sourceAttr is already in the mappings list on state, remove that sourceAttr pair from the mappings list.
  if (sourceAttr && sourceAttrIndex !== undefined) {
    updatedMappings.splice(sourceAttrIndex, 1)

    if (targetAttr) {
      // Then, push the new mapped pair into the mappings list.
      updatedMappings.push({
        source_attribute: sourceAttr,
        target_attribute: targetAttr,
      })
      return updatedMappings
    }
  }

  // If we're mapping this sourceAttr from scratch, just add the new mapped pair to the mappings list.
  if (sourceAttr && targetAttr) {
    return [
      ...updatedMappings,
      {
        source_attribute: sourceAttr,
        target_attribute: targetAttr,
      },
    ]
  }
  return updatedMappings
}

export const preMapOnName = (
  sourceAttrs: IMappingAttribute[],
  targetAttrs: IMappingAttribute[],
  mappings: IAttributeMap[]
): IAttributeMap[] => {
  const updatedMappings = [...mappings]
  sourceAttrs.map((sourceAttr, i) => {
    const maybeMatchingTargetAttr = targetAttrs.find(
      targetAttr =>
        targetAttr.name === sourceAttr.name &&
        targetAttr.data_type === sourceAttr.data_type
    )
    if (maybeMatchingTargetAttr) {
      const sourceAttrIndex = i
      updatedMappings.splice(sourceAttrIndex, 1)
      updatedMappings.push({
        source_attribute: sourceAttr,
        target_attribute: maybeMatchingTargetAttr,
      })
    }
  })
  return updatedMappings
}
