import {
  createAsyncAction,
  ActionType,
  createStandardAction,
} from 'typesafe-actions'
import {
  IAttributeMap,
  IGetAttributeMappingsResponseData,
  ICreateMappingAttributesResponseData,
  IMappingAttribute,
} from 'api/response'
import { AttributeType } from 'store/attribute-mapper/reducer'

export const getExistingContactAttrMappings = createAsyncAction(
  '@@@mascot/attribute-mapper/FETCH_EXISTING_CONTACT_ATTR_MAPPINGS_REQUEST',
  '@@@mascot/attribute-mapper/FETCH_EXISTING_CONTACT_ATTR_MAPPINGS_SUCCESS',
  '@@@mascot/attribute-mapper/FETCH_EXISTING_CONTACT_ATTR_MAPPINGS_FAILURE'
)<void, IGetAttributeMappingsResponseData, void>()

export const getExistingOrgAttrMappings = createAsyncAction(
  '@@@mascot/attribute-mapper/FETCH_EXISTING_ORG_ATTR_MAPPINGS_REQUEST',
  '@@@mascot/attribute-mapper/FETCH_EXISTING_ORG_ATTR_MAPPINGS_SUCCESS',
  '@@@mascot/attribute-mapper/FETCH_EXISTING_ORG_ATTR_MAPPINGS_FAILURE'
)<void, IGetAttributeMappingsResponseData, void>()

export const createNewAttributes = createAsyncAction(
  '@@@mascot/attribute-mapper/CREATE_NEW_ATTRIBUTES_REQUEST',
  '@@@mascot/attribute-mapper/CREATE_NEW_ATTRIBUTES_SUCCESS',
  '@@@mascot/attribute-mapper/CREATE_NEW_ATTRIBUTES_FAILURE'
)<
  { attrType: AttributeType },
  ICreateMappingAttributesResponseData & { attrType: AttributeType },
  { attrType: AttributeType }
>()

export const updateMappingsAsync = createAsyncAction(
  '@@@mascot/attribute-mapper/UPDATE_MAPPINGS_REQUEST',
  '@@@mascot/attribute-mapper/UPDATE_MAPPINGS_SUCCESS',
  '@@@mascot/attribute-mapper/UPDATE_MAPPINGS_FAILURE'
)<void, { contact: IAttributeMap[]; institution: IAttributeMap[] }, void>()

export const updateMappingsSync = createStandardAction(
  '@@mascot/attribute-mapper/UPDATE_MAPPINGS'
)<{
  sourceAttrId: number
  targetAttrId: number
  unmap: boolean
  attrType?: AttributeType
  placeholder?: boolean
}>()

export const preMapOnName = createStandardAction(
  '@@mascot/attribute-mapper/PRE_MAP_ON_NAME'
)<{ attrType: AttributeType }>()
export const cancelMapping = createStandardAction(
  '@@mascot/attribute-mapper/CANCEL_MAPPING'
)()

export const addTargetAttribute = createStandardAction(
  '@@mascot/attribute-mapper/ADD_TARGET_ATTRIBUTE'
)<IMappingAttribute & { attrType: AttributeType }>()

export type IAttributeMapperActions =
  | ActionType<typeof getExistingContactAttrMappings>
  | ActionType<typeof getExistingOrgAttrMappings>
  | ActionType<typeof updateMappingsSync>
  | ActionType<typeof createNewAttributes>
  | ActionType<typeof updateMappingsAsync>
  | ActionType<typeof preMapOnName>
  | ActionType<typeof cancelMapping>
  | ActionType<typeof addTargetAttribute>
