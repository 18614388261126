import ClassNames from 'classnames'
import {
  ContextMenu,
  IContextMenuGenAIProps,
} from 'components/ContextMenu/ContextMenu'
import { AttributeBlock } from 'components/DraftEditor/AttributeBlock'
import 'components/DraftEditor/DraftEditor.scss'
import {
  CONTACT_ATTRIBUTE_PREFIX,
  generateEntityMapper,
  getOutputWithAttributeValues,
  IContactAttributeEntity,
  IInstitutionAttributeEntity,
  INSTITUTION_ATTRIBUTE_PREFIX,
  ITopLevelContactFieldEntity,
  MutableEnum,
  TOPLEVEL_FIELD_PREFIX,
} from 'components/DraftEditor/draftUtils'
import { FancyTextCounter } from 'components/FancyTextBox/FancyTextBox'
import { Spinner } from 'components/Spinner/Spinner'
import Tooltip from 'components/Tooltip/Tooltip'
import { SINGLE_MESSAGE_SOFT_CAP } from 'const/settings'
import {
  CompositeDecorator,
  ContentBlock,
  ContentState,
  convertFromRaw,
  convertToRaw,
  Editor,
  EditorState,
  EntityInstance,
  Modifier,
  SelectionState,
} from 'draft-js'
import 'draft-js/dist/Draft.css'
import { EmojiData } from 'emoji-mart'
import emojiRegex from 'emoji-regex'
import * as React from 'react'
import {
  IContactAttributeDraftEditor,
  IInstitutionAttributeDraftEditor,
} from 'store/knowledgeSeeder/reducer'
import { getAllAttributesAndFieldsAsync } from 'store/personalization/contactAttributes/thunks'
import { getAttributeByIdMapping } from 'store/personalization/selectors'
import { isInitial, isLoading, RDK } from 'store/webdata'
import { useDispatch, useSelector } from 'util/hooks'

export type GenAIDraftEditorProps = Pick<
  IContextMenuGenAIProps,
  'summaryQuestion' | 'understandingId'
>

interface IDraftEditorProps {
  initialAnswer: string
  onChange: (answer: string) => void
  textBoxLabel: string
  toggleResetDraftEditor: boolean
  includeSensitiveContactAttributes: boolean
  disableInstitutionAttributes?: boolean
  disableContactAttributes?: boolean
  hasContactAttributes?: boolean
  onPickImage?: (imageLink: string, name?: string) => void
  error?: string
  className?: string
  containerClassName?: string
  onFocusClassName?: string
  onErrorClassName?: string
  onErrorTextClassName?: string
  autoFocus?: boolean
  genAIProps?: GenAIDraftEditorProps
}

interface IDecoratedComponentProps {
  decoratedText: string
  entityKey: string
  contentState: ContentState
  children: React.ReactNode
}

interface IEmojiComponentProps {
  entityKey: string
  emoji: string
  contentState: ContentState
  children: React.ReactNode
}
export enum AttributeKind {
  INSTITUTION = 'institution',
  CONTACT = 'contact',
  TOPLEVEL = 'toplevel',
}

const DecoratedMustacheComponent = (props: IDecoratedComponentProps) => {
  const entity: EntityInstance = props.contentState.getEntity(props.entityKey)
  const entityData:
    | IContactAttributeEntity
    | ITopLevelContactFieldEntity
    /* tslint:disable-next-line:no-unsafe-any */
    | IInstitutionAttributeEntity = entity.getData()
  return (
    <AttributeBlock attribute={entityData}>{props.children}</AttributeBlock>
  )
}

const EmojiComponent = (props: IEmojiComponentProps) => {
  const entity: EntityInstance = props.contentState.getEntity(props.entityKey)
  /* tslint:disable-next-line:no-unsafe-any */
  const entityData: { emoji: string } = entity.getData()

  // We display emoji as background elements so they don't break rendering
  // and offset data in DraftJS

  const emojiPattern = emojiRegex()
  const emojiMatch = emojiPattern.exec(entityData.emoji)

  if (!emojiMatch) {
    throw Error('Emoji regex match failed')
  }

  return (
    <span data-emoji={emojiMatch[0]} className="emoji-spacing">
      {props.children}
    </span>
  )
}

function getEntityStrategy() {
  return (
    contentBlock: ContentBlock,
    callback: (start: number, end: number) => void,
    contentState: ContentState
  ) => {
    contentBlock.findEntityRanges(character => {
      const entityKey = character.getEntity()
      if (entityKey === null) {
        return false
      } else {
        // LINKs are automatically converted to Entities in ContentState
        // https://draftjs.org/docs/advanced-topics-entities/
        // We only want to decorate attributes and leave links as plain text
        // this entity type check allows us to be discerning about which types of entities we decorate (only ones we've created from attributes)
        const entity = contentState.getEntity(entityKey)
        if (entity.getType() !== 'attribute') {
          return false
        }
      }
      return true
    }, callback)
  }
}

function getEmojiStrategy() {
  return (
    contentBlock: ContentBlock,
    callback: (start: number, end: number) => void,
    contentState: ContentState
  ) => {
    contentBlock.findEntityRanges(character => {
      const entityKey = character.getEntity()
      if (entityKey === null) {
        return false
      } else {
        const entity = contentState.getEntity(entityKey)
        if (entity.getType() !== 'emoji') {
          return false
        }
      }
      return true
    }, callback)
  }
}

const compositeDecorator: CompositeDecorator = new CompositeDecorator([
  {
    strategy: getEntityStrategy(),
    component: DecoratedMustacheComponent,
  },
  {
    strategy: getEmojiStrategy(),
    component: EmojiComponent,
  },
])

const KB_HEAP_EVENT_LOCATION = 'knowledge base'

export const useGetAttributeMapping = () => {
  const dispatch = useDispatch()
  const mappingResults = useSelector(getAttributeByIdMapping)
  const scriptyMappingResults = useSelector(state =>
    getAttributeByIdMapping(state, true)
  )

  // TODO: We should be using useLocation from react-router here, but somehow, it's returning location as undefined
  // Using window.location for now
  const location = window.location

  const [mappingResultsToUse] = React.useState(
    location.pathname.startsWith('/campaign-script-library')
      ? scriptyMappingResults
      : mappingResults
  )

  React.useEffect(() => {
    if (isInitial(mappingResultsToUse)) {
      getAllAttributesAndFieldsAsync(dispatch)(
        false,
        location.pathname.startsWith('/campaign-script-library')
      )
    }
  }, [dispatch, mappingResultsToUse, location.pathname])

  return mappingResultsToUse
}
const DraftEditor = ({
  initialAnswer,
  onChange,
  textBoxLabel,
  toggleResetDraftEditor,
  disableInstitutionAttributes,
  disableContactAttributes,
  hasContactAttributes,
  includeSensitiveContactAttributes,
  onPickImage,
  error,
  className,
  containerClassName = 'editor rounded',
  onFocusClassName,
  onErrorClassName = 'border-danger bw-1px',
  onErrorTextClassName = 'text-danger',
  autoFocus,
  genAIProps,
}: IDraftEditorProps) => {
  const initialEditorState = EditorState.createWithContent(
    ContentState.createFromText('')
  )
  const [editorState, setEditorState] = React.useState(
    autoFocus
      ? EditorState.moveFocusToEnd(initialEditorState)
      : initialEditorState
  )

  const [localAnswer, setLocalAnswer] = React.useState(initialAnswer)
  const [isFocused, setIsFocused] = React.useState(true)

  const mappingResults = useGetAttributeMapping()

  const mapping = React.useMemo(() => {
    return mappingResults?.kind === RDK.Success
      ? mappingResults.data
      : { institution: {}, contact: {}, toplevel: {} }
    // when 'kind' changes, the 'data' changes with it for mappingResults, so we can safely omit the
    // object 'data' from the dependency list and still avoid stale closures and have state be in sync
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mappingResults?.kind])

  const [output, setOutput] = React.useState(
    getOutputWithAttributeValues(initialAnswer, mapping)
  )

  React.useEffect(() => {
    // this separate useEffect resets the localAnswer in the editor when the initialAnswer props change
    // this allows us to put a new answer/output on state when the selected row in KB seeder list
    // otherwise, the answer content in the editor will not remain up-to-date
    setLocalAnswer(initialAnswer)
  }, [initialAnswer])

  React.useEffect(() => {
    const entityMapper = generateEntityMapper(localAnswer, mapping)
    const contentState = entityMapper
      ? convertFromRaw(entityMapper)
      : ContentState.createFromText(localAnswer)

    const editorState = EditorState.moveSelectionToEnd(
      EditorState.createWithContent(contentState, compositeDecorator)
    )
    setEditorState(editorState)

    const output = getOutputWithAttributeValues(localAnswer, mapping)

    setOutput(output)
  }, [mapping, localAnswer, toggleResetDraftEditor])

  const replaceEmojisWithEntities = (
    newEditorState: EditorState
  ): EditorState => {
    // TLDR: handle the addition of emojis via the emoji picker, copy/paste, native OS emoji-picker in content
    // given the current EditorState iterate through the content
    // identify emojis using 'emoji-regex', remove them from the content, and add an Entity for each to the ContentState
    // return a new EditorState that reflects the content state w/ these added entities

    const contentState = newEditorState.getCurrentContent()
    const rawContentState = convertToRaw(contentState)
    const selectionState = newEditorState.getSelection()
    const pattern = emojiRegex()

    const blocks: ContentBlock[] = contentState.getBlocksAsArray()
    let nextState = newEditorState

    blocks.forEach((_block, i) => {
      const content = rawContentState.blocks[i].text
      const emojiPositions = []
      const entityOffsets = new Set()
      let match: RegExpExecArray | null = pattern.exec(content)

      while (match) {
        emojiPositions.push({ index: match.index, emoji: match[0] })
        entityOffsets.add(match.index)
        match = pattern.exec(content)
      }

      emojiPositions.forEach(elem => {
        let contentStateWithEmojiEntity = contentState.createEntity(
          'emoji',
          MutableEnum.IMMUTABLE,
          { emoji: elem.emoji }
        )

        const entityKey = contentStateWithEmojiEntity.getLastCreatedEntityKey()

        /* eslint-disable-next-line @typescript-eslint/consistent-type-assertions */
        const replacementSelectionState: SelectionState = selectionState.merge({
          anchorOffset: elem.index,
          focusOffset: elem.index + elem.emoji.length,
          hasFocus: true,
        }) as SelectionState

        contentStateWithEmojiEntity = Modifier.replaceText(
          contentStateWithEmojiEntity,
          replacementSelectionState,
          ' ',
          undefined,
          entityKey
        )

        nextState = EditorState.push(
          nextState,
          contentStateWithEmojiEntity,
          'apply-entity'
        )
        setEditorState(nextState)
      })
    })

    setEditorState(nextState)
    return nextState
  }

  const handleEditorChange = (
    newEditorState: EditorState,
    onChange: (answer: string) => void
  ) => {
    // as content in editor changes, convert the answer back to the format the backend expects
    // i.e.: replace entities with {{ institution.attributeId }} in answer
    // this version of the answer is what needs to be sent back up to the parent
    newEditorState = replaceEmojisWithEntities(newEditorState)
    const contentState = newEditorState.getCurrentContent()
    const rawContentState = convertToRaw(contentState)

    // https://github.com/facebook/draft-js/issues/1198#issuecomment-535651492
    const currentContentTextLength = editorState
      .getCurrentContent()
      .getPlainText().length
    const newContentTextLength = newEditorState
      .getCurrentContent()
      .getPlainText().length

    if (currentContentTextLength === 0 && newContentTextLength === 1) {
      // WORKAROUND: listens to input changes and focuses/moves cursor to back after typing in first character
      setEditorState(EditorState.moveFocusToEnd(newEditorState))
    } else {
      setEditorState(newEditorState)
    }

    const blocks: ContentBlock[] = contentState.getBlocksAsArray()

    // a variable to store the raw output string from decorated values
    let rawOutputString = ''

    // iterate through content blocks (account for content w/ line breaks)
    blocks.forEach((block, i) => {
      const entityOffsets: Set<number> = new Set(
        rawContentState.blocks[i].entityRanges.map(elem => elem.offset)
      )

      const answer = rawContentState.blocks[i].text

      block.findEntityRanges(
        () => true,
        (start, end) => {
          if (entityOffsets.has(start)) {
            const entityKey = block.getEntityAt(start)
            const entity: EntityInstance = contentState.getEntity(entityKey)
            if (entity.getType() === 'attribute') {
              // tslint:disable-next-line: no-unsafe-any
              const { originalText } = entity.getData()
              rawOutputString += `{{ ${originalText} }}`
            } else if (entity.getType() === 'emoji') {
              // tslint:disable-next-line: no-unsafe-any
              rawOutputString += entity.getData()['emoji']
            } else if (entity.getType() === 'LINK') {
              // tslint:disable-next-line: no-unsafe-any
              rawOutputString += entity.getData()['href'] || ''
            }
          } else {
            rawOutputString += answer.slice(start, end)
          }
        }
      )
      if (blocks.length > 1 && i < blocks.length - 1) {
        rawOutputString += '\n'
      }
    })

    // set output on local state as the version of the answer w/ _actual values_
    // this allows for the character count to be correct and reflective of the count of characters in the outgoing SMS
    setOutput(getOutputWithAttributeValues(rawOutputString, mapping))
    // send output with attributes replaced with {{ institution.attributeId }} back to the parent
    onChange(rawOutputString)
  }

  const getOriginalText = (
    kind: AttributeKind,
    attribute: IInstitutionAttributeDraftEditor | IContactAttributeDraftEditor
  ) => {
    switch (kind) {
      case AttributeKind.INSTITUTION:
        return `${INSTITUTION_ATTRIBUTE_PREFIX}${attribute.id}`
      case AttributeKind.TOPLEVEL:
        return `${TOPLEVEL_FIELD_PREFIX}${attribute.field}`
      case AttributeKind.CONTACT:
        return `${CONTACT_ATTRIBUTE_PREFIX}${attribute.id}`
    }
  }

  const insertAttribute = (
    attribute: IInstitutionAttributeDraftEditor | IContactAttributeDraftEditor,
    editorState: EditorState,
    handleEditorChange: (
      nextState: EditorState,
      onChange: (output: string) => void
    ) => void,
    onChange: (output: string) => void,
    kind: AttributeKind
  ) => {
    const contentState = editorState.getCurrentContent()
    const selectionState = editorState.getSelection()
    const entityData = {
      id: attribute.id,
      value: attribute.value,
      field: attribute.field,
      type: attribute.type,
      originalText: getOriginalText(kind, attribute),
      kind,
    }
    const contentStateWithEntity = contentState.createEntity(
      'attribute',
      MutableEnum.IMMUTABLE,
      entityData
    )
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey()
    const modifiedContent = Modifier.replaceText(
      contentState,
      selectionState,
      entityData.field,
      undefined,
      entityKey
    )
    const nextState = EditorState.push(
      editorState,
      modifiedContent,
      editorState.getLastChangeType()
    )
    handleEditorChange(nextState, onChange)
  }

  const insertText = (
    text: string,
    editorState: EditorState,
    handleEditorChange: (
      nextState: EditorState,
      onChange: (output: string) => void
    ) => void,
    onChange: (output: string) => void
  ) => {
    const contentState = editorState.getCurrentContent()
    const currentSelection = editorState.getSelection()
    const newContent = Modifier.replaceText(
      contentState,
      currentSelection,
      text
    )
    const newEditorState = EditorState.push(
      editorState,
      newContent,
      'insert-characters'
    )
    handleEditorChange(newEditorState, onChange)
  }

  const insertEmoji = (
    emoji: EmojiData,
    editorState: EditorState,
    handleEditorChange: (
      nextState: EditorState,
      onChange: (output: string) => void
    ) => void,
    onChange: (output: string) => void
  ) => {
    insertText(emoji.native, editorState, handleEditorChange, onChange)
  }

  const insertLink = (
    link: string,
    editorState: EditorState,
    handleEditorChange: (
      nextState: EditorState,
      onChange: (output: string) => void
    ) => void,
    onChange: (output: string) => void
  ) => insertText(link, editorState, handleEditorChange, onChange)

  const softCap = SINGLE_MESSAGE_SOFT_CAP
  const countTextType =
    softCap && output && output.length > softCap
      ? 'text-danger'
      : 'text-mainstay-dark-blue-50'

  const classNames = ClassNames(
    containerClassName,
    'bg-white',
    className,
    isFocused ? onFocusClassName : '',
    {
      [onErrorClassName]: !!error,
    }
  )

  return (
    <div className={classNames}>
      <div className="mt-3 ml-3 mb-3 text-muted default-answer">
        {textBoxLabel}
      </div>
      {isLoading(mappingResults) ? (
        <Spinner className="stroke-bg-mainstay-dark-blue" />
      ) : (
        <div className="position-relative">
          <Editor
            spellCheck
            placeholder="Provide a response..."
            onChange={(editorState: EditorState) => {
              handleEditorChange(editorState, onChange)
            }}
            editorState={editorState}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
          />
        </div>
      )}
      <div className="d-flex ml-2 mb-1 text-muted align-items-center justify-content-between">
        <ContextMenu
          popoverPlacement="top-end"
          flags={{
            institutionAttributeSelector: !disableInstitutionAttributes,
            contactAttributeSelector: !disableContactAttributes,
            link: true,
            emoji: true,
            image: !!onPickImage,
            genAIFireFly: !!genAIProps,
          }}
          onPickEmoji={emoji =>
            insertEmoji(emoji, editorState, handleEditorChange, onChange)
          }
          onLinkShorten={link =>
            insertLink(link, editorState, handleEditorChange, onChange)
          }
          onPickImage={onPickImage}
          onPickContactAttribute={attribute => {
            // top level contact field do not have an 'id' associated with them
            const kind = !!attribute.id
              ? AttributeKind.CONTACT
              : AttributeKind.TOPLEVEL
            insertAttribute(
              attribute,
              editorState,
              handleEditorChange,
              onChange,
              kind
            )
          }}
          onPickInstitutionAttribute={attribute =>
            insertAttribute(
              attribute,
              editorState,
              handleEditorChange,
              onChange,
              AttributeKind.INSTITUTION
            )
          }
          includeSensitiveContactAttributess={includeSensitiveContactAttributes}
          fireFlyEventTrackerObj={{
            eventLocation: KB_HEAP_EVENT_LOCATION,
            eventAction: 'click',
            eventObject: 'ai response write',
          }}
          genAIProps={
            genAIProps
              ? {
                  ...genAIProps,
                  onSelectGenAIAnswer: response => {
                    setLocalAnswer(response)
                    onChange(response)
                  },
                }
              : undefined
          }
        />
        <Tooltip
          isEnabled={hasContactAttributes}
          content="Answer length may vary due to contact fields">
          <div>
            <FancyTextCounter
              value={output}
              softCap={SINGLE_MESSAGE_SOFT_CAP}
              countTextType={countTextType}
              className="mr-2 mt-2"
              isApproximate={hasContactAttributes}
            />
          </div>
        </Tooltip>
      </div>
      {error && <div className={onErrorTextClassName}>{error}</div>}
    </div>
  )
}

export default DraftEditor
