import {
  getAllContactAttributes,
  getAllScriptyContactAttributes,
  listTopLevelContactFields,
} from 'store/personalization/contactAttributes/actions'
import { Dispatch } from 'store/store'
import * as Api from 'api'
import { AxiosError } from 'typings/axios'
import { toast } from 'mainstay-ui-kit/MainstayToast/MainstayToast'
import { toastOnHttpError500or400 } from 'api/http'
import {
  getAllInstitutionAttributes,
  getAllScriptyInstitutionAttributes,
} from 'store/personalization/institutionAttributes/actions'
import { isRight, isLeft } from 'fp-ts/lib/Either'

export const getAllContactAttributesAsync = (dispatch: Dispatch) => {
  return () => {
    dispatch(getAllContactAttributes.request())
    return Api.fetchContactAttributes({ pageSize: 2000 })
      .then(response => {
        dispatch(getAllContactAttributes.success(response.data.results))
      })
      .catch((err: AxiosError) => {
        if (err.response && err.response.status === 404) {
          toast('Failed to fetch contact attributes.', {
            type: 'error',
            options: { autoClose: 1000 },
          })
        }
        toastOnHttpError500or400(err)
        dispatch(getAllContactAttributes.failure(err))
      })
  }
}

export const listTopLevelContactFieldsAsync = (dispatch: Dispatch) => () => {
  dispatch(listTopLevelContactFields.request())
  return Api.fetchTopLevelContactFields()
    .then(res => {
      dispatch(listTopLevelContactFields.success(res.data))
    })
    .catch((e: AxiosError) => {
      toastOnHttpError500or400(e)
      dispatch(listTopLevelContactFields.failure(e))
    })
}

export const getAllAttributesAndFieldsAsync = (dispatch: Dispatch) => (
  locationsUsed = false,
  fromScriptLibrary = false
) => {
  const getAllContactAttributesAction = fromScriptLibrary
    ? getAllScriptyContactAttributes
    : getAllContactAttributes
  const getAllInstitutionAttributesAction = fromScriptLibrary
    ? getAllScriptyInstitutionAttributes
    : getAllInstitutionAttributes

  dispatch(getAllInstitutionAttributesAction.request())
  dispatch(getAllContactAttributesAction.request())
  dispatch(listTopLevelContactFields.request())
  return Api.fetchAllAttributesAndFields(locationsUsed, fromScriptLibrary)
    .then(res => {
      if (isRight(res)) {
        dispatch(
          getAllInstitutionAttributesAction.success(
            res.right.institutionAttributes
          )
        )
        dispatch(
          getAllContactAttributesAction.success(res.right.contactAttributes)
        )
        dispatch(listTopLevelContactFields.success(res.right.topLevelFields))
      }
      if (isLeft(res)) {
        throw new Error('Failed to fetch attributes and fields')
      }
    })
    .catch((e: AxiosError) => {
      toastOnHttpError500or400(e)
      dispatch(getAllInstitutionAttributesAction.failure(e))
      dispatch(getAllContactAttributesAction.failure(e))
      dispatch(listTopLevelContactFields.failure(e))
    })
}
