import * as Api from 'api'
import {
  IContactAttributeResponseData,
  ISaveableTopLevelContactFieldsResponseData,
  ITopLevelContactFieldsResponseData,
} from 'api/response'
import {
  ActionType,
  createAsyncAction,
  createStandardAction,
} from 'typesafe-actions'

export const getAllContactAttributes = createAsyncAction(
  '@@MASCOT/PERSONALIZATION/GET_ALL_CONTACT_ATTRIBUTES_START',
  '@@MASCOT/PERSONALIZATION/GET_ALL_CONTACT_ATTRIBUTES_SUCCESS',
  '@@MASCOT/PERSONALIZATION/GET_ALL_CONTACT_ATTRIBUTES_FAILURE'
)<void, Array<IContactAttributeResponseData>, Api.IApiError>()

export const getAllScriptyContactAttributes = createAsyncAction(
  '@@MASCOT/PERSONALIZATION/GET_ALL_SCRIPTY_CONTACT_ATTRIBUTES_START',
  '@@MASCOT/PERSONALIZATION/GET_ALL_SCRIPTY_CONTACT_ATTRIBUTES_SUCCESS',
  '@@MASCOT/PERSONALIZATION/GET_ALL_SCRIPTY_CONTACT_ATTRIBUTES_FAILURE'
)<void, Array<IContactAttributeResponseData>, Api.IApiError>()

export const updateContactAttributesPageNumber = createStandardAction(
  '@@MASCOT/PERSONALIZATION/UPDATE_CONTACT_ATTRS_PAGE_NUMBER'
)<number>()

export const clearAllContactAttributes = createStandardAction(
  '@@MASCOT/PERSONALIZATION/UPDATE_CONTACT_ATTRIBUTE'
)<void>()

export const listTopLevelContactFields = createAsyncAction(
  '@@MASCOT/PERSONALIZATION/LIST_TOP_LEVEL_CONTACT_FIELDS_START',
  '@@MASCOT/PERSONALIZATION/LIST_TOP_LEVEL_CONTACT_FIELDS_END',
  '@@MASCOT/PERSONALIZATION/LIST_TOP_LEVEL_CONTACT_FIELDS_FAIL'
)<void, ITopLevelContactFieldsResponseData, Api.IApiError>()

export const listSaveableTopLevelContactFields = createAsyncAction(
  '@@MASCOT/PERSONALIZATION/LIST_SAVEABLE_TOP_LEVEL_CONTACT_FIELDS_START',
  '@@MASCOT/PERSONALIZATION/LIST_SAVEABLE_TOP_LEVEL_CONTACT_FIELDS_END',
  '@@MASCOT/PERSONALIZATION/LIST_SAVEABLE_TOP_LEVEL_CONTACT_FIELDS_FAIL'
)<void, ISaveableTopLevelContactFieldsResponseData, Api.IApiError>()

export type IPersonalizationContactActions = ActionType<
  | typeof getAllContactAttributes
  | typeof getAllScriptyContactAttributes
  | typeof updateContactAttributesPageNumber
  | typeof clearAllContactAttributes
  | typeof listTopLevelContactFields
  | typeof listSaveableTopLevelContactFields
>
