import React from 'react'
import { IInstitution } from 'api/response'
import { ToggleSwitch } from 'components/ToggleSwitch/ToggleSwitch'
import * as api from 'api'
import { TextInput } from 'components/TextInput/TextInput'
import { Button } from 'components/Button/Button'
import Select from 'components/Select/SelectV2'
import {
  institutionSettingsUpdateReducer,
  updateFromProps,
  saveField,
  handleInputChange,
  cancelChanges,
} from 'components/SettingsInstitutionUpdateField/settingsInstitutionUpdateFieldReducer'
import { SettingWithoutField } from 'components/SettingWithoutField/SettingWithoutField'

interface IOption {
  label: string
  value: string
}

interface IInstitutionSettingUpdateFieldProps {
  readonly helpText?: string | JSX.Element
  readonly placeholder?: string
  readonly dropdownOptions?: IOption[]
  readonly name: string
  readonly value: string | null | undefined | boolean | number
  readonly reloadOnUpdate?: boolean
  readonly fieldName: keyof Pick<
    IInstitution,
    | 'name'
    | 'abbr'
    | 'displayName'
    | 'oliName'
    | 'hubspotId'
    | 'hubspotParentId'
    | 'botType'
    | 'botStatus'
    | 'animal'
    | 'numberOfContactsPurchased'
    | 'package'
    | 'knowledgePacks'
    | 'channels'
  >
}

// todo(jsimms): rewrite, its a reimplementation of formik and doesnt include anything convenient like validation
export function InstitutionSettingUpdateField({
  helpText,
  placeholder,
  value,
  name,
  reloadOnUpdate,
  dropdownOptions,
  fieldName,
}: IInstitutionSettingUpdateFieldProps) {
  const [state, dispatch] = React.useReducer(institutionSettingsUpdateReducer, {
    initial: value,
    changed: value,
    updateStatus: 'initial',
  })
  React.useEffect(() => {
    dispatch(updateFromProps(value))
  }, [value])

  const handleSave = () => {
    dispatch(saveField.request())
    const payload =
      typeof state.changed === 'string'
        ? {
            // This is a hack to get rid of emojis from the request payload.
            // Ideally this would be in the form validation library we use as a regex util.
            [fieldName]: (state.changed || '').replace(
              /([\uE000-\uF8FF]|\uD83C[\uDF00-\uDFFF]|\uD83D[\uDC00-\uDDFF])/g,
              ''
            ),
          }
        : { [fieldName]: state.changed }

    api
      .updateInstitutionData('me', payload)
      .then(res => {
        const value = res.data[fieldName]
        if (typeof value !== 'object') {
          dispatch(saveField.success(value))
        }
        // Field specific hack, since we dont update the redux store when we fire this event
        // Maybe this form should be tied to redux since the settings effect application state 😬
        if (reloadOnUpdate) {
          window.location.reload()
        }
      })
      .catch(() => {
        dispatch(saveField.failure())
      })
  }

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const changed = e.target.value
    dispatch(handleInputChange(changed))
  }

  const handleToggleValue = (checked: boolean) => {
    dispatch(handleInputChange(checked))
  }

  const handleCancel = () => dispatch(cancelChanges())

  const updating = state.updateStatus === 'loading'
  const contentChanged = state.changed !== state.initial

  const textValue = state.changed === null ? undefined : state.changed

  return (
    <SettingWithoutField name={name} helpText={helpText}>
      <div className="d-flex align-items-center mb-2">
        {dropdownOptions ? (
          <Select
            className="w-100"
            value={{ label: textValue, value: textValue }}
            options={dropdownOptions}
            onChange={value => {
              // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
              const changed = (value as IOption).value
              if (changed) {
                dispatch(handleInputChange(changed))
              }
            }}
          />
        ) : typeof value === 'boolean' ? (
          <ToggleSwitch
            onChange={handleToggleValue}
            checked={Boolean(state.changed)}
          />
        ) : (
          <TextInput
            name={name}
            type={Number.isInteger(value) ? 'number' : 'input'}
            min={0}
            onChange={onChange}
            value={textValue?.toString()}
            placeholder={placeholder}
            onKeyDown={e => {
              if (e.key === 'Enter') {
                e.preventDefault()
                handleSave()
              }
            }}
            required
          />
        )}
      </div>
      {contentChanged ? (
        <div className="d-flex justify-content-end">
          <Button height="small" className="mr-2" onClick={handleCancel}>
            Cancel
          </Button>
          <Button
            height="small"
            color="primary"
            onClick={handleSave}
            loading={updating}>
            Save Changes
          </Button>
        </div>
      ) : null}
    </SettingWithoutField>
  )
}
