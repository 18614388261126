import React from 'react'
import Col from 'reactstrap/lib/Col'
import Row from 'reactstrap/lib/Row'
import { Button } from 'components/Button/Button'
import { TextInput } from 'components/TextInput/TextInput'
import { SectionTitle, ReadonlyIcon } from 'page/SettingsGeneral'
import * as api from 'api'
import { toast } from 'mainstay-ui-kit/MainstayToast/MainstayToast'
import {
  setQuantity,
  purchasePhoneNumbersFormReducer,
  setAreaCode,
  purchasePhoneNumbers,
  IPurchasePhoneNumbersFormReducerState,
} from 'components/PurchasePhoneNumbersForm/purchasePhoneNumbersFormReducer'
import { AxiosError } from 'typings/axios'
import { keyOrDefault } from 'api/http'
import { useFeatures } from 'util/hooks'
import { parsePhoneNumber } from 'awesome-phonenumber'
import { IPhoneNumber } from 'page/SettingsSMS'
import { SettingWithoutField } from 'components/SettingWithoutField/SettingWithoutField'

interface IPhoneNumberListProps {
  readonly phoneNumbers: Array<IPhoneNumber> | null
}

export function PhoneNumberList({ phoneNumbers }: IPhoneNumberListProps) {
  const { hasFeature, FeaturesType } = useFeatures()

  if (hasFeature(FeaturesType.INTL_TEXTING_V1)) {
    return <IntlPhoneNumberList phoneNumbers={phoneNumbers} />
  }

  return (
    <section className="overflow-y-auto" style={{ maxHeight: '200px' }}>
      {phoneNumbers != null ? (
        phoneNumbers.map((phoneNumber: IPhoneNumber) => (
          <p className="mb-1" key={phoneNumber.phoneE164}>
            {phoneNumber.phoneE164}
          </p>
        ))
      ) : (
        <b className="text-muted">None</b>
      )}
    </section>
  )
}

interface IIntlPhoneNumberListProps {
  readonly phoneNumbers: Array<IPhoneNumber> | null
}

export function IntlPhoneNumberList({
  phoneNumbers,
}: IIntlPhoneNumberListProps) {
  return (
    <div className="d-flex">
      <ReadonlyIcon />
      <section className="overflow-y-auto" style={{ maxHeight: '200px' }}>
        {phoneNumbers != null ? (
          phoneNumbers.map((phoneNumber: IPhoneNumber) => (
            <IntlPhoneNumber
              key={phoneNumber.phoneE164}
              phoneNumber={phoneNumber}
            />
          ))
        ) : (
          <b className="text-muted">None</b>
        )}
      </section>
    </div>
  )
}

/**
 * https://dev.to/jorik/country-code-to-flag-emoji-a21
 */
export function getFlagEmoji(regionCode: string) {
  const codePoints = regionCode
    .toUpperCase()
    .split('')
    .map(char => 127397 + char.charCodeAt(0))
  return String.fromCodePoint(...codePoints)
}

export function IntlPhoneNumber({
  phoneNumber,
}: {
  phoneNumber: IPhoneNumber
}) {
  const parsedPhoneNumber = parsePhoneNumber(
    phoneNumber.phoneE164,
    phoneNumber.countryCode
  )
  const regionCode =
    phoneNumber.countryCode || parsedPhoneNumber.getRegionCode()
  return (
    <p className="d-flex align-items-center mb-0" key={phoneNumber.phoneE164}>
      <span className="mr-1 fs-24px">{getFlagEmoji(regionCode)}</span>{' '}
      {parsedPhoneNumber.getNumber('international')}
    </p>
  )
}

interface IFormFieldProps {
  readonly name: string
  readonly value: string | number | string[] | undefined
  readonly placeholder: string
  readonly type?: React.InputHTMLAttributes<HTMLInputElement>['type']
  readonly maxLength?: number
  readonly onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
}

function FormField({
  name,
  value,
  placeholder,
  type,
  onChange,
  maxLength,
}: IFormFieldProps) {
  return (
    <SettingWithoutField name={name}>
      <div className="d-flex align-items-center">
        <TextInput
          name={name}
          value={value}
          placeholder={placeholder}
          type={type}
          onChange={onChange}
          maxLength={maxLength}
          required
        />
      </div>
    </SettingWithoutField>
  )
}

interface IPurchasePhoneNumbersFormProps {
  readonly phoneNumbers: Array<IPhoneNumber> | null
  readonly initialState?: IPurchasePhoneNumbersFormReducerState
}

export function PurchasePhoneNumbersForm({
  phoneNumbers,
  initialState = {
    phoneNumbers: phoneNumbers || [],
    areaCode: '',
    quantity: '0',
    purchaseStatus: 'initial',
  },
}: IPurchasePhoneNumbersFormProps) {
  const [state, dispatch] = React.useReducer(
    purchasePhoneNumbersFormReducer,
    initialState
  )

  const handleQuantityChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    dispatch(setQuantity(e.target.value))

  const handleAreaCodeChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    dispatch(setAreaCode(e.target.value))

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    dispatch(purchasePhoneNumbers.request())
    api
      .buyPhoneNumbersForInstitution({
        areaCode: state.areaCode,
        quantity: state.quantity,
      })
      .then(res => {
        toast.success('successfully purchased phone numbers')
        dispatch(purchasePhoneNumbers.success(res.data))
      })
      .catch((err: AxiosError) => {
        const errorMsg = keyOrDefault(
          err,
          'error',
          'Error purchasing numbers. Please try again later.'
        )
        toast.error(errorMsg)
        dispatch(purchasePhoneNumbers.failure())
      })
  }
  return (
    <form onSubmit={handleSubmit}>
      <SectionTitle>Purchase Phone Numbers</SectionTitle>
      <Row className="mb-3">
        <Col lg={7}>
          <FormField
            name="Quantity"
            value={state.quantity}
            placeholder="10"
            type="number"
            onChange={handleQuantityChange}
          />
          <FormField
            name="Area Code"
            value={state.areaCode}
            placeholder="605"
            onChange={handleAreaCodeChange}
            maxLength={3}
          />
          <Button
            color="primary"
            outlined
            type="submit"
            loading={state.purchaseStatus === 'loading'}>
            Purchase
          </Button>
        </Col>
        <Col>
          <p>Current numbers ({state.phoneNumbers.length})</p>
          <PhoneNumberList phoneNumbers={state.phoneNumbers} />
        </Col>
      </Row>
    </form>
  )
}

interface ICountryCodeListProps {
  readonly phoneNumbers: Array<IPhoneNumber> | null
}

interface ICountryCodeInfo {
  regionCode: string // 'US', 'GB'
  countryCallingCode: string // '+1', '+44'
}

export function CountryCodeList({ phoneNumbers }: ICountryCodeListProps) {
  const countryCodes = phoneNumbers?.reduce(
    (acc: Array<ICountryCodeInfo>, cur: IPhoneNumber) => {
      if (!acc.some(codes => codes.regionCode === cur.countryCode)) {
        const parsedPhoneNumber = parsePhoneNumber(
          cur.phoneE164,
          cur.countryCode
        )
        acc.push({
          regionCode: cur.countryCode || parsedPhoneNumber.getRegionCode(),
          countryCallingCode: `+${parsedPhoneNumber.getCountryCode()}`,
        })
      }
      return acc
    },
    []
  )
  return (
    <div className="d-flex">
      <ReadonlyIcon />
      <section className="overflow-y-auto" style={{ maxHeight: '200px' }}>
        {countryCodes?.map(countryCode => (
          <p className="mb-1" key={countryCode.countryCallingCode}>
            {getFlagEmoji(countryCode.regionCode)} ({countryCode.regionCode}){' '}
            {countryCode.countryCallingCode}
          </p>
        ))}
      </section>
    </div>
  )
}
