import * as React from 'react'
import { SectionTitle } from 'page/SettingsGeneral'
import Select from 'components/Select/SelectV2'
import { Formik, Form, Field } from 'formik'
import { ToggleSwitch } from 'components/ToggleSwitch/ToggleSwitch'
import * as Api from 'api'
import { isRight } from 'fp-ts/lib/Either'
import { toast } from 'mainstay-ui-kit/MainstayToast/MainstayToast'
import { minutesToDisplayUnits } from 'util/timeConversion'
import {
  WebData,
  Loading,
  Success,
  Failure,
  isLoading,
  isSuccess,
} from 'store/webdata'
import { Spinner } from 'components/Spinner/Spinner'
import { SettingWithoutField } from 'components/SettingWithoutField/SettingWithoutField'

interface IContactAuthSettings {
  enabled: boolean
  webBotImmediateAuth: boolean
  email: string | null
  primarySuccessDuration: number
  primaryFailureDuration: number
  secondarySuccessDuration: number
  secondaryFailureDuration: number
  codeDuration: number
  allEmails: string[]
}

const mapResponseDataToAuthSettings = ({
  auth_settings,
  all_emails,
}: {
  auth_settings: {
    enabled: boolean
    web_bot_immediate_auth: boolean
    email_field_name: string | null
    minutes_until_primary_contact_auth_success_expires: number
    minutes_until_primary_contact_auth_failure_expires: number
    minutes_until_secondary_contact_auth_success_expires: number
    minutes_until_secondary_contact_auth_failure_expires: number
    minutes_until_code_expires: number
  }
  all_emails: string[]
}) => {
  return {
    enabled: auth_settings.enabled,
    webBotImmediateAuth: auth_settings.web_bot_immediate_auth,
    email: auth_settings.email_field_name,
    primarySuccessDuration:
      auth_settings.minutes_until_primary_contact_auth_success_expires,
    primaryFailureDuration:
      auth_settings.minutes_until_primary_contact_auth_failure_expires,
    secondarySuccessDuration:
      auth_settings.minutes_until_secondary_contact_auth_success_expires,
    secondaryFailureDuration:
      auth_settings.minutes_until_secondary_contact_auth_failure_expires,
    codeDuration: auth_settings.minutes_until_code_expires,
    allEmails: all_emails,
  }
}

interface IContactAuthSettingUpdateFieldProps {
  readonly helpText?: string | JSX.Element
  readonly dropdownOptions: { value: number | string | null; label: string }[]

  readonly name: string | JSX.Element
  readonly value: { value: string | number | null; label: string }
  readonly onChange: (value: string | number) => void
}

const ContactAuthSettingUpdateField = ({
  helpText,
  name,
  dropdownOptions,
  value,
  onChange,
}: IContactAuthSettingUpdateFieldProps) => {
  return (
    <SettingWithoutField name={name} helpText={helpText}>
      <div className="d-flex align-items-center mb-2">
        <Select
          className="w-100"
          value={value}
          options={dropdownOptions}
          onChange={v => {
            // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
            onChange((v as { value: string | number; label: string }).value)
          }}
        />
      </div>
    </SettingWithoutField>
  )
}

const valueToOption = (valueInMinutes: number) => ({
  value: valueInMinutes,
  label: minutesToDisplayUnits(valueInMinutes),
})

const authExpirationOptions = [
  1,
  2,
  3,
  4,
  5,
  7,
  10,
  15,
  30,
  60,
  90,
  180,
  365,
].map(v => valueToOption(v * 1440))
const codeExpirationOptions = [0.5, 1, 2, 3, 6, 12, 24].map(v =>
  valueToOption(v * 60)
)

export default function ContactAuthSettings() {
  const [settings, setSettings] = React.useState<
    WebData<IContactAuthSettings, 'error'>
  >()
  React.useEffect(() => {
    setSettings(Loading())
    Api.getInstitutionContactAuthSettings().then(res => {
      if (isRight(res)) {
        setSettings(Success(mapResponseDataToAuthSettings(res.right)))
      } else {
        setSettings(Failure('error'))
      }
    })
  }, [setSettings])
  return isSuccess(settings) ? (
    <>
      <SectionTitle>Authentication</SectionTitle>
      <p className="mb-2">
        Authenticate contact identity with a verification code
      </p>
      <Formik<IContactAuthSettings>
        initialValues={settings.data}
        enableReinitialize={true}
        onSubmit={() => {
          return
        }}
        render={({ setFieldValue, values }) => {
          const handleChange = (
            fieldName:
              | 'enabled'
              | 'webBotImmediateAuth'
              | 'email'
              | 'primarySuccessDuration'
              | 'primaryFailureDuration'
              | 'secondarySuccessDuration'
              | 'secondaryFailureDuration'
              | 'codeDuration',
            selectedValue: string | boolean | number | null
          ) => {
            setFieldValue(fieldName, selectedValue)
            Api.updateInstitutionContactAuthSettings({
              ...values,
              [fieldName]: selectedValue,
            }).then(res => {
              if (isRight(res)) {
                toast.success('Successfully updated setting.')
              } else {
                toast.error('Failure updating setting.')
                setFieldValue(fieldName, values[fieldName])
              }
            })
          }

          return (
            <Form>
              <Field
                name="enabled"
                render={() => (
                  <SettingWithoutField
                    name="Enable Authentication"
                    helpText="Protects sensitive contact fields from being displayed or used in filters for unauthorized contacts">
                    <div className="d-flex align-items-center">
                      <ToggleSwitch
                        checked={values.enabled}
                        onChange={nextValue => {
                          handleChange('enabled', nextValue)
                        }}
                      />
                    </div>
                  </SettingWithoutField>
                )}
              />
              <p className="text-muted mb-2 w-75">
                Contacts will only be asked to authenticate if a bot response
                contains authenticated information. Contacts must authenticate
                their identity with an emailed verification code before
                receiving the answer.
              </p>
              <Field
                name="webBotImmediateAuth"
                render={() => (
                  <SettingWithoutField
                    name="Automatically ask to authenticate webchat users"
                    helpText="Ask all new webchat users if they would like to authenticate. Only send webchat intro if the contact declines or fails">
                    <div className="d-flex align-items-center">
                      <ToggleSwitch
                        checked={values.webBotImmediateAuth}
                        onChange={nextValue => {
                          handleChange('webBotImmediateAuth', nextValue)
                        }}
                      />
                    </div>
                  </SettingWithoutField>
                )}
              />
              <Field
                name="email"
                render={() => (
                  <ContactAuthSettingUpdateField
                    name="Authenticate with"
                    value={{
                      value: values.email || null,
                      label: values.email || "(Default 'email' field)",
                    }}
                    dropdownOptions={[
                      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
                      { value: null, label: "(Default 'email' field)" } as {
                        value: string | null
                        label: string
                      },
                    ].concat(
                      settings.data.allEmails.map(v => ({
                        value: v,
                        label: v,
                      }))
                    )}
                    onChange={nextValue => {
                      handleChange('email', nextValue)
                    }}
                    helpText=""
                  />
                )}
              />
              <Field
                name="primarySuccessDuration"
                render={() => (
                  <ContactAuthSettingUpdateField
                    name={
                      <div>
                        Authenticate <strong>known</strong> contacts for
                      </div>
                    }
                    value={valueToOption(values.primarySuccessDuration)}
                    dropdownOptions={authExpirationOptions}
                    helpText=""
                    onChange={nextValue => {
                      handleChange('primarySuccessDuration', nextValue)
                    }}
                  />
                )}
              />
              <Field
                name="primaryFailureDuration"
                render={() => (
                  <ContactAuthSettingUpdateField
                    name={
                      <div>
                        If failed or declined, <strong>known</strong> contacts
                        will be eligible for authentication again after
                      </div>
                    }
                    value={valueToOption(values.primaryFailureDuration)}
                    dropdownOptions={authExpirationOptions}
                    onChange={nextValue => {
                      handleChange('primaryFailureDuration', nextValue)
                    }}
                    helpText=""
                  />
                )}
              />
              <Field
                name="secondarySuccessDuration"
                render={() => (
                  <ContactAuthSettingUpdateField
                    name={
                      <div>
                        Authenticate <strong>unknown</strong> contacts for
                      </div>
                    }
                    value={valueToOption(values.secondarySuccessDuration)}
                    dropdownOptions={authExpirationOptions}
                    helpText=""
                    onChange={nextValue => {
                      handleChange('secondarySuccessDuration', nextValue)
                    }}
                  />
                )}
              />
              <Field
                name="secondaryFailureDuration"
                render={() => (
                  <ContactAuthSettingUpdateField
                    name={
                      <div>
                        If failed or declined, <strong>unknown</strong> contacts
                        will be eligible for authentication again after
                      </div>
                    }
                    value={valueToOption(values.secondaryFailureDuration)}
                    dropdownOptions={authExpirationOptions}
                    onChange={nextValue => {
                      handleChange('secondaryFailureDuration', nextValue)
                    }}
                    helpText=""
                  />
                )}
              />
              <Field
                name="codeDuration"
                render={() => (
                  <ContactAuthSettingUpdateField
                    name="Authentication code expires after"
                    value={valueToOption(values.codeDuration)}
                    dropdownOptions={codeExpirationOptions}
                    helpText=""
                    onChange={nextValue => {
                      handleChange('codeDuration', nextValue)
                    }}
                  />
                )}
              />
            </Form>
          )
        }}
      />
    </>
  ) : isLoading(settings) ? (
    <Spinner className="stroke-bg-mainstay-dark-blue" size="sm" />
  ) : (
    <p className="text-danger">
      Contact authentication settings failed to load. Please contact support.
    </p>
  )
}
