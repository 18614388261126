import { getType } from 'typesafe-actions'
import {
  createNewAttributes,
  preMapOnName,
  updateMappingsSync,
  updateMappingsAsync,
  cancelMapping,
  addTargetAttribute,
  getExistingContactAttrMappings,
  getExistingOrgAttrMappings,
} from 'store/attribute-mapper/actions'
import { IMappingAttribute, IAttributeMap } from 'api/response'
import { WebData, Loading, Success, Failure, isSuccess } from 'store/webdata'
import {
  updateMapping as updateMappingUtil,
  preMapOnName as preMapOnNameUtil,
} from 'components/AttributeMapper/utils'
import { IActions } from 'store/store'

export const attributeTypes = ['CONTACT', 'INSTITUTION'] as const
export type AttributeType = typeof attributeTypes[number]

export const parseAttributeType = (typeString: string): AttributeType => {
  const maybeAttributeType: unknown = typeString
  if (
    typeof maybeAttributeType === 'string' &&
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    attributeTypes.includes(maybeAttributeType as AttributeType)
  ) {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    return maybeAttributeType as AttributeType
  }
  throw new Error('Invalid AttributeType.')
}

interface IAttributeMapperState {
  sourceInstitution: string | undefined
  targetInstitution: string | undefined
  attributeType: 'CONTACT' | 'INSTITUTION' | undefined
  sourceAttrs: WebData<IMappingAttribute[]>
  targetAttrs: WebData<IMappingAttribute[]>
  mappings: WebData<IAttributeMap[]>
  contactAttrMappings: WebData<IAttributeMap[]>
  orgAttrMappings: WebData<IAttributeMap[]>
  attrCreationsLoading: boolean
  mappingsLoading: boolean
  sourceContactAttrs: WebData<IMappingAttribute[]>
  sourceOrgAttrs: WebData<IMappingAttribute[]>
  targetContactAttrs: WebData<IMappingAttribute[]>
  targetOrgAttrs: WebData<IMappingAttribute[]>
}

const initialState: IAttributeMapperState = {
  sourceInstitution: undefined,
  targetInstitution: undefined,
  attributeType: undefined,
  sourceAttrs: undefined,
  targetAttrs: undefined,
  contactAttrMappings: undefined,
  orgAttrMappings: undefined,
  sourceContactAttrs: undefined,
  sourceOrgAttrs: undefined,
  targetContactAttrs: undefined,
  targetOrgAttrs: undefined,
  mappings: undefined,
  attrCreationsLoading: false,
  mappingsLoading: false,
}

const attributeMappingReducer = (
  state: IAttributeMapperState = initialState,
  action: IActions
): IAttributeMapperState => {
  switch (action.type) {
    case getType(getExistingContactAttrMappings.request): {
      return {
        ...state,
        sourceContactAttrs: Loading(),
        targetContactAttrs: Loading(),
        mappingsLoading: true,
      }
    }
    case getType(getExistingOrgAttrMappings.request): {
      return {
        ...state,
        sourceOrgAttrs: Loading(),
        targetOrgAttrs: Loading(),
        mappingsLoading: true,
      }
    }
    case getType(getExistingContactAttrMappings.success): {
      return {
        ...state,
        sourceContactAttrs: Success(action.payload.source_attrs),
        targetContactAttrs: Success(action.payload.target_attrs),
        contactAttrMappings: Success(action.payload.mappings),
        mappingsLoading: false,
      }
    }
    case getType(getExistingOrgAttrMappings.success): {
      return {
        ...state,
        sourceOrgAttrs: Success(action.payload.source_attrs),
        targetOrgAttrs: Success(action.payload.target_attrs),
        orgAttrMappings: Success(action.payload.mappings),
        mappingsLoading: false,
      }
    }
    case getType(getExistingContactAttrMappings.failure): {
      return {
        ...state,
        sourceContactAttrs: Failure(
          'Failed to fetch source contact attributes'
        ),
        targetContactAttrs: Failure(
          'Failed to fetch target contact attributes'
        ),
        contactAttrMappings: Failure(
          'Failed to fetch existing contact attribute mappings'
        ),
        mappingsLoading: false,
      }
    }
    case getType(getExistingOrgAttrMappings.failure): {
      return {
        ...state,
        sourceOrgAttrs: Failure('Failed to fetch source org attributes'),
        targetOrgAttrs: Failure('Failed to fetch target org attributes'),
        orgAttrMappings: Failure(
          'Failed to fetch existing org attribute mappings'
        ),
        mappingsLoading: false,
      }
    }
    case getType(createNewAttributes.request): {
      const targetAttrsKey =
        action.payload.attrType === 'CONTACT'
          ? 'targetContactAttrs'
          : 'targetOrgAttrs'

      return {
        ...state,
        [targetAttrsKey]: Loading(),
        attrCreationsLoading: true,
      }
    }
    case getType(createNewAttributes.success): {
      const targetAttrsKey =
        action.payload.attrType === 'CONTACT'
          ? 'targetContactAttrs'
          : 'targetOrgAttrs'

      return {
        ...state,
        [targetAttrsKey]: Success(action.payload.target_attributes),
        contactAttrMappings:
          action.payload.attrType === 'CONTACT'
            ? Success([
                ...(isSuccess(state.contactAttrMappings)
                  ? state.contactAttrMappings.data
                  : []),
                ...Success(action.payload.mappings).data,
              ])
            : Success(state.contactAttrMappings).data,
        orgAttrMappings:
          action.payload.attrType === 'INSTITUTION'
            ? Success([
                ...(isSuccess(state.orgAttrMappings)
                  ? state.orgAttrMappings.data
                  : []),
                ...Success(action.payload.mappings).data,
              ])
            : Success(state.orgAttrMappings).data,
        attrCreationsLoading: false,
      }
    }
    case getType(createNewAttributes.failure): {
      const targetAttrsKey =
        action.payload.attrType === 'CONTACT'
          ? 'targetContactAttrs'
          : 'targetOrgAttrs'
      const stateTargetAttrs =
        action.payload.attrType === 'CONTACT'
          ? state.targetContactAttrs
          : state.targetOrgAttrs

      return {
        ...state,
        [targetAttrsKey]: stateTargetAttrs,
        contactAttrMappings: isSuccess(state.contactAttrMappings)
          ? Success(state.contactAttrMappings).data
          : Failure('Failed to fetch mappings'),
        orgAttrMappings: isSuccess(state.orgAttrMappings)
          ? Success(state.orgAttrMappings).data
          : Failure('Failed to fetch mappings'),
        attrCreationsLoading: false,
      }
    }
    case getType(updateMappingsAsync.request): {
      return {
        ...state,
        contactAttrMappings: Loading(),
        orgAttrMappings: Loading(),
      }
    }
    case getType(updateMappingsAsync.success): {
      return {
        ...state,
        contactAttrMappings: Success(action.payload.contact),
        orgAttrMappings: Success(action.payload.institution),
      }
    }
    case getType(updateMappingsAsync.failure): {
      return {
        ...state,
        contactAttrMappings: state.contactAttrMappings,
        orgAttrMappings: state.orgAttrMappings,
      }
    }
    case getType(updateMappingsSync): {
      const { mappings, sourceAttrs, targetAttrs } =
        action.payload?.attrType === 'CONTACT'
          ? {
              mappings: state.contactAttrMappings,
              sourceAttrs: state.sourceContactAttrs,
              targetAttrs: state.targetContactAttrs,
            }
          : {
              mappings: state.orgAttrMappings,
              sourceAttrs: state.sourceOrgAttrs,
              targetAttrs: state.targetOrgAttrs,
            }

      if (
        isSuccess(mappings) &&
        isSuccess(sourceAttrs) &&
        isSuccess(targetAttrs)
      ) {
        const updatedMappings =
          action.payload &&
          updateMappingUtil(
            mappings.data,
            action.payload?.sourceAttrId,
            action.payload?.targetAttrId,
            sourceAttrs.data,
            targetAttrs.data,
            action.payload?.unmap
          )
        if (action.payload?.attrType === 'CONTACT') {
          return {
            ...state,
            contactAttrMappings: Success(
              updatedMappings || mappings.data || []
            ),
          }
        }
        if (action.payload?.attrType === 'INSTITUTION') {
          return {
            ...state,
            orgAttrMappings: Success(updatedMappings || mappings.data || []),
          }
        }
        return state
      }
    }
    case getType(preMapOnName): {
      const mappingsKey =
        action.payload.attrType === 'CONTACT'
          ? 'contactAttrMappings'
          : 'orgAttrMappings'
      const stateMappings =
        action.payload.attrType === 'CONTACT'
          ? state.contactAttrMappings
          : state.orgAttrMappings
      const stateSourceAttrs =
        action.payload.attrType === 'CONTACT'
          ? state.sourceContactAttrs
          : state.sourceOrgAttrs
      const stateTargetAttrs =
        action.payload.attrType === 'CONTACT'
          ? state.targetContactAttrs
          : state.targetOrgAttrs
      if (
        isSuccess(stateMappings) &&
        isSuccess(stateSourceAttrs) &&
        isSuccess(stateTargetAttrs)
      ) {
        const updatedMappings = preMapOnNameUtil(
          stateSourceAttrs.data,
          stateTargetAttrs.data,
          stateMappings.data
        )
        return {
          ...state,
          [mappingsKey]: Success(updatedMappings || stateMappings.data || []),
        }
      }
    }
    case getType(cancelMapping): {
      return {
        ...state,
        sourceInstitution: undefined,
        targetInstitution: undefined,
        sourceContactAttrs: undefined,
        sourceOrgAttrs: undefined,
        targetContactAttrs: undefined,
        targetOrgAttrs: undefined,
        contactAttrMappings: undefined,
        orgAttrMappings: undefined,
        attributeType: undefined,
      }
    }
    case getType(addTargetAttribute): {
      const targetAttrs =
        action.payload?.attrType === 'CONTACT'
          ? state.targetContactAttrs
          : action.payload?.attrType === 'INSTITUTION'
          ? state.targetOrgAttrs
          : state.targetAttrs

      if (isSuccess(targetAttrs)) {
        if (action.payload?.attrType === 'CONTACT') {
          return {
            ...state,
            targetContactAttrs: Success([...targetAttrs.data, action.payload]),
          }
        }
        if (action.payload?.attrType === 'INSTITUTION') {
          return {
            ...state,
            targetOrgAttrs: Success([...targetAttrs.data, action.payload]),
          }
        }
        return state
      }
    }
    default:
      return state
  }
}

export default attributeMappingReducer
