import { Row, Col } from 'reactstrap'
import { classNames } from 'react-select/lib/utils'

interface ISettingWithoutFieldProps {
  readonly name: string | JSX.Element
  readonly children: React.ReactNode
  readonly helpText?: string | JSX.Element
  readonly helpElement?: JSX.Element
  readonly className?: string
}

export function SettingWithoutField({
  name,
  children,
  helpText,
  helpElement,
  className,
}: ISettingWithoutFieldProps) {
  return (
    <>
      <hr className="w-100" />
      <Row className={classNames('mb-4', className)}>
        <Col lg={3}>{name}</Col>
        <Col lg={4}>{children}</Col>
        <Col>
          <div className="caption text-muted mb-0">
            {helpElement || <p className="caption">{helpText}</p>}
          </div>
        </Col>
      </Row>
    </>
  )
}
