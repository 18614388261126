import { IVCard } from 'store/campaign-scripts/reducer'
import { Channel } from 'store/transport'
import { notUndefined } from 'util/typeguards'

export enum ConversationDetailsRequestStatus {
  initial = 'initial',
  loading = 'loading',
  error = 'error',
  contactNotFound = 'contactNotFound',
  invalidChannel = 'invalidChannel',
  ok = 'ok',
}

export enum GenerativeResponseRequestStatus {
  initial = 'initial',
  loading = 'loading',
  error = 'error',
  ok = 'ok',
}

export const MESSAGES_LOAD_MORE_SCROLL_OFFSET = 500
export const UNDERSTANDING_URL_PARAM = 'understanding_id'
export const FALLBACK_URL_PARAM = 'fallback_id'

export type MessageSender =
  | {
      readonly kind: 'bot'
    }
  | {
      readonly kind: 'college_staff'
      readonly name: string | null
      readonly firstName: string | null
      readonly lastName: string | null
    }
  | {
      readonly kind: 'admithub_staff'
    }
  | {
      readonly kind: 'email'
    }
  | { readonly kind: 'unknown' }

export type Media = {
  readonly url: string
  readonly contentType: string | null
  readonly deleted?: boolean
}

export type MessageOrigin =
  | { readonly kind: 'understanding'; readonly understandingId: string }
  | { readonly kind: 'dialog'; readonly dialogId: string }
  | { readonly kind: 'ai_generated'; readonly generativeTransactionId: number }
  | {
      readonly kind: 'ai_assisted_live_chat'
      readonly generativeTransactionId: number
    }
  | {
      readonly kind: 'fallback'
      readonly understandingId: string
      readonly generativeTransactionId?: number | null
    }

export enum KnowledgeReviewReason {
  BotCouldNotAnswer = 'bot_could_not_answer',
  NeedsPersonalizedAnswer = 'needs_personalized_answer',
  NeedsInteractiveAnswer = 'needs_interactive_answer',
  AnswerIncorrect = 'answer_incorrect',
}

/**
 * NeedsPersonalizedAnswer and NeedsInteractiveAnswer are not included because they are not implemented right now.
 */
export const ALL_REASONS = [
  KnowledgeReviewReason.BotCouldNotAnswer,
  KnowledgeReviewReason.AnswerIncorrect,
]

export const MARK_TITLES: {
  [key in KnowledgeReviewReason]: string
} = {
  [KnowledgeReviewReason.BotCouldNotAnswer]: 'Missed Questions',
  [KnowledgeReviewReason.NeedsPersonalizedAnswer]: 'Needs Personalized Answer',
  [KnowledgeReviewReason.NeedsInteractiveAnswer]: 'Needs Interactive Answer',
  [KnowledgeReviewReason.AnswerIncorrect]: 'Marked as Incorrect',
}

export const MARK_DESCRIPTIONS: {
  [key in KnowledgeReviewReason]: string
} = {
  [KnowledgeReviewReason.BotCouldNotAnswer]:
    'Find a response and add knowledge for messages your bot could not match',
  [KnowledgeReviewReason.NeedsPersonalizedAnswer]:
    'Answer questions and update responses marked for review by your bot or your team',
  [KnowledgeReviewReason.NeedsInteractiveAnswer]:
    'Answer questions and update responses marked for review by your bot or your team',
  [KnowledgeReviewReason.AnswerIncorrect]:
    'Responses that have been marked by your team as incorrect and need review',
}

export type KnowledgeReviewMark = {
  reason: KnowledgeReviewReason
  note: string | null
  reviewed?: boolean | null
}

export type Feedback = {
  rating: string
  comment: string | null
  createdAt: string
}

export type OutgoingMessage = {
  readonly direction: 'outgoing'
  readonly id: string
  readonly inflightId: string | null
  readonly text: string
  readonly media: Media | null
  readonly mediaFiles: readonly Media[] | undefined
  readonly sourceText: string | null
  readonly createdAt: string
  readonly sender: MessageSender | null
  readonly vCard?: IVCard | null
  readonly deliveryFailure: string | null
  readonly origin: MessageOrigin | null
  readonly knowledgeReviewMark: KnowledgeReviewMark | null
  readonly feedback: Feedback | null
  readonly channel: string
  readonly generativeAIAssisted: boolean | undefined
}

/** A message we've sent to Neolith and are waiting for acknowledgement.
 *
 * Similar to OutgoingMessage
 */
export type InflightMessage = {
  readonly direction: 'outgoing'
  readonly id: string
  readonly inflightId: string
  readonly text: string
  readonly media: Media | null
  readonly mediaFiles: readonly Media[] | undefined
  readonly sourceText: null
  readonly createdAt: string
  readonly sender: null
  readonly deliveryFailure: null
  readonly origin: null
  readonly knowledgeReviewMark: null
  readonly feedback: null
  readonly channel: string
  readonly generativeAIAssisted: boolean | undefined
  readonly genAItransactionId: number | undefined
}

export type IncomingMessage = {
  readonly flagged: boolean | undefined
  readonly escalated: boolean | undefined
  readonly direction: 'incoming'
  readonly id: string
  readonly text: string
  readonly media: Media | null
  readonly mediaFiles: readonly Media[] | undefined
  readonly sourceText: string | null
  readonly createdAt: string
  readonly knowledgeReviewMark: KnowledgeReviewMark | null
  readonly channel: string
  readonly vCard?: IVCard | null
}

export type ConversationMessage = OutgoingMessage | IncomingMessage

export type ConversationContact = {
  readonly id: string
  readonly name: string | null
  readonly preferredName: string | null
  readonly phone: string | null
  readonly email: string | null
  readonly lastMessageAt: string | null
  readonly translationUsed: boolean
  readonly conversationReadTimestamp: string | null
  readonly lastUnreadMessageId: string | null
  readonly unreadMessagesStatusByChannel: { [key: string]: boolean }
  readonly chatState: LiveChatState
  readonly channels: Channel[]
}

export type Escalation = {
  readonly id: string
  readonly counselorNames: string[] | null
  readonly createdAt: string
  readonly emails: string[] | null
  readonly message: IncomingMessage | null
  readonly channel: string
}

export type Reset = {
  readonly id: string
  readonly createdAt: string
  readonly channel: string
}

export type LiveChatState = { readonly channel: string | null } & (
  | { readonly kind: 'messagingDisabled' }
  | { readonly kind: 'liveChatOff' }
  | { readonly kind: 'liveChatStarting' }
  | { readonly kind: 'liveChatFailed' }
  | { readonly kind: 'liveChatExpired' }
  | {
      readonly kind: 'liveChatActive'
      readonly timeRemainingSeconds: number
      readonly user: {
        readonly id: string | null
        readonly name: string | null
      }
    }
  | { readonly kind: 'unknown' }
)

export type SelectedImageState = {
  url: string
  name?: string
}

/**
 * Details about a reply to a flagged message to which the user selected.
 */
export type ReplyToFlagState = {
  /**
   * ID of the message to which is being responded.
   */
  readonly messageId: string

  /**
   * A suggested message to send to the contact in reply.
   */
  readonly draft: string
}

export type Conversation = {
  readonly contact: ConversationContact | null
  readonly escalations: readonly Escalation[]
  readonly resets: readonly Reset[]
  readonly messages: readonly ConversationMessage[]
  readonly hasMoreNewer: boolean | null
  readonly hasMoreOlder: boolean | null
}

export type FlaggedMessage = {
  readonly id: string
  readonly createdAt: string
  readonly channel: string | null
}

export type FlaggedMessages = {
  messages: FlaggedMessage[]
  count: number
}

export type MultiChannelFlaggedMessages = FlaggedMessages & {
  byChannel: { [k: string]: FlaggedMessage[] }
}

export const getLastMessageTimestamp = (
  lastMessage: ConversationMessage | InflightMessage | null,
  contact: ConversationContact | null
) => {
  // This is only needed until all old data in contacts smsInfo.lastMessageAt is aligned
  // with createdAt from smslogs (https://github.com/AdmitHub/NeOliTh/pull/1346) to fix invalid data in DB.
  // We had misalignment when we were not using smslogs.createdAt field for setting smsInfo.lastMessageAt value on conatact collection.
  // Data will be aligned once we receive the new message for in all conversations, from that
  // point smsInfo.lastMessageAt will have needed value.

  if (lastMessage && lastMessage.createdAt && !contact) {
    return lastMessage.createdAt
  }
  if (
    !lastMessage ||
    !lastMessage.createdAt ||
    !contact ||
    !contact.lastMessageAt
  ) {
    return new Date().toISOString()
  }

  const timestamp =
    new Date(lastMessage.createdAt) > new Date(contact.lastMessageAt)
      ? lastMessage.createdAt
      : contact.lastMessageAt

  return new Date(timestamp).toISOString()
}
export function getInflightMessageIds(
  messages: readonly ConversationMessage[]
): Set<string> {
  const inflightIds = messages
    .map(x => {
      if (x.direction === 'outgoing') {
        return x.inflightId || undefined
      }
      return undefined
    })
    .filter(notUndefined)

  return new Set(inflightIds)
}

export function createInflightMessage(params: {
  readonly inflightId: string
  readonly text: string
  readonly media: Media | null
  readonly createdAt: string
  readonly channel?: string
  readonly generativeAIAssisted?: boolean
  readonly genAItransactionId?: number
}): InflightMessage {
  return {
    createdAt: params.createdAt,
    inflightId: params.inflightId,
    id: params.inflightId,
    sender: null,
    text: params.text,
    media: params.media,
    mediaFiles: params.media != null ? [params.media] : [],
    direction: 'outgoing',
    sourceText: null,
    origin: null,
    feedback: null,
    knowledgeReviewMark: null,
    deliveryFailure: null,
    channel: params.channel ?? '',
    generativeAIAssisted: params.generativeAIAssisted,
    genAItransactionId: params.genAItransactionId,
  }
}

export function knowledgeReviewRouterParamToEnum(
  reason: string
): KnowledgeReviewReason | undefined {
  if (reason === 'bot-could-not-answer') {
    return KnowledgeReviewReason.BotCouldNotAnswer
  }
  if (reason === 'needs-personalized-answer') {
    return KnowledgeReviewReason.NeedsPersonalizedAnswer
  }
  if (reason === 'needs-interactive-answer') {
    return KnowledgeReviewReason.NeedsInteractiveAnswer
  }
  if (reason === 'answer-incorrect') {
    return KnowledgeReviewReason.AnswerIncorrect
  }

  return undefined
}

export function knowledgeReviewReasonToEnum(
  reason: string | null
): KnowledgeReviewReason {
  if (reason === 'bot_could_not_answer') {
    return KnowledgeReviewReason.BotCouldNotAnswer
  }
  if (reason === 'needs_personalized_answer') {
    return KnowledgeReviewReason.NeedsPersonalizedAnswer
  }
  if (reason === 'needs_interactive_answer') {
    return KnowledgeReviewReason.NeedsInteractiveAnswer
  }
  if (reason === 'answer_incorrect') {
    return KnowledgeReviewReason.AnswerIncorrect
  }

  return KnowledgeReviewReason.BotCouldNotAnswer
}
