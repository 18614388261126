import React from 'react'
import { IInstitution } from 'api/response'
import * as api from 'api'
import { Button } from 'components/Button/Button'
import Select from 'components/Select/SelectV2'
import {
  institutionSettingsMetadataUpdateReducer,
  updateFromProps,
  saveField,
  handleInputChange,
  cancelChanges,
} from 'components/SettingsInstitutionUpdateField/settingsInstitutionMetadataUpdateFieldReducer'
import { SettingWithoutField } from 'components/SettingWithoutField/SettingWithoutField'

interface IOption {
  label: string
  value: string
}

interface IMetadataCategory {
  id: string
  name: string
  createdAt?: string
}

interface IInstitutionSettingUpdateFieldProps {
  readonly helpText?: string | JSX.Element
  readonly dropdownOptions?: IOption[]
  readonly name: string
  readonly multi?: boolean
  readonly value: IMetadataCategory | Array<IMetadataCategory> | undefined
  fieldName: keyof Pick<IInstitution, 'package' | 'knowledgePacks' | 'channels'>
}

export function InstitutionMetadataUpdateField({
  helpText,
  value,
  multi,
  name,
  dropdownOptions,
  fieldName,
}: IInstitutionSettingUpdateFieldProps) {
  const [state, dispatch] = React.useReducer(
    institutionSettingsMetadataUpdateReducer,
    {
      initial: value,
      changed: value,
      updateStatus: 'initial',
    }
  )

  React.useEffect(() => {
    dispatch(updateFromProps(value))
  }, [value])

  const handleSave = () => {
    dispatch(saveField.request())
    const payload = { [fieldName]: state.changed }
    api
      .updateInstitutionData('me', payload)
      .then(res => {
        dispatch(
          saveField.success(
            // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
            res.data[fieldName] as IMetadataCategory | IMetadataCategory[]
          )
        )
      })
      .catch(() => {
        dispatch(saveField.failure())
      })
  }

  const handleCancel = () => dispatch(cancelChanges())

  const updating = state.updateStatus === 'loading'
  const contentChanged = state.changed !== state.initial

  const selectValue = state.changed === null ? undefined : state.changed
  const selectValueArray = React.useMemo(
    () =>
      Array.isArray(selectValue)
        ? selectValue.map(v => ({ value: v.id, label: v.name }))
        : selectValue,
    [selectValue]
  )

  return (
    <SettingWithoutField name={name} helpText={helpText}>
      <div className="d-flex align-items-center mb-2">
        <Select
          className="w-100"
          value={
            Array.isArray(selectValue)
              ? selectValueArray
              : { label: selectValue?.name, value: selectValue?.id }
          }
          isMulti={multi}
          options={dropdownOptions}
          onChange={value => {
            // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
            const changed = value as IOption
            if (changed) {
              if (Array.isArray(changed)) {
                dispatch(
                  handleInputChange(
                    changed.map((c: IOption) => ({
                      name: c.label,
                      id: c.value,
                    }))
                  )
                )
              } else {
                dispatch(
                  handleInputChange({ name: changed.label, id: changed.value })
                )
              }
            }
          }}
        />
      </div>
      {contentChanged ? (
        <div className="d-flex justify-content-end">
          <Button height="small" className="mr-2" onClick={handleCancel}>
            Cancel
          </Button>
          <Button
            height="small"
            color="primary"
            onClick={handleSave}
            loading={updating}>
            Save Changes
          </Button>
        </div>
      ) : null}
    </SettingWithoutField>
  )
}
