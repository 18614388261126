import {
  IContactAttributes,
  IContactAttribute,
  ITopLevelContactFields,
} from 'store/personalization/contactAttributes/selectors'
import {
  IPersonalizationContactActions,
  getAllContactAttributes as getAllContactAttributesAction,
  getAllScriptyContactAttributes,
  listTopLevelContactFields as listTopLevelContactFieldsAction,
  clearAllContactAttributes,
  listSaveableTopLevelContactFields,
} from 'store/personalization/contactAttributes/actions'
import { getType } from 'typesafe-actions'
import {
  IContactAttributeResponseData,
  ISaveableTopLevelContactFieldsResponseData,
} from 'api/response'
import {
  WebData,
  Loading,
  Success,
  Failure,
  RefetchingOrLoading,
} from 'store/webdata'
import { IAttributesById } from 'store/personalization/institutionAttributes/selectors'

interface IPersonalizationContactAttrsState {
  contactAttributes: WebData<IContactAttributes>
  allContactAttributes: WebData<IContactAttributes>
  allScriptyContactAttributes: WebData<IContactAttributes>
  topLevelContactFields: WebData<ITopLevelContactFields>
  contactAttributesRes: WebData<IContactAttributeResponseData[]>
  scriptyContactAttributesRes: WebData<IContactAttributeResponseData[]>
  saveableTopLevelFieldsRes: WebData<ISaveableTopLevelContactFieldsResponseData>
  ui: {
    pageNumber: number
  }
  countContactAttributes: number
  attributesById: IAttributesById
}

const INITIAL_PERSONALIZATION_STATE: IPersonalizationContactAttrsState = {
  contactAttributes: Loading(),
  allContactAttributes: undefined,
  allScriptyContactAttributes: undefined,
  topLevelContactFields: undefined,
  contactAttributesRes: undefined,
  scriptyContactAttributesRes: undefined,
  saveableTopLevelFieldsRes: undefined,
  ui: {
    pageNumber: 1,
  },
  countContactAttributes: 0,
  attributesById: { institution: {}, contact: {}, toplevel: {} },
}

export const mapResponseDataToAttributes = (
  data: IContactAttributeResponseData[]
): IContactAttributes => {
  return data.map(
    (elem: IContactAttributeResponseData): IContactAttribute => ({
      id: elem.id,
      field: elem.name,
      type: elem.data_type,
      requires_auth: elem.requires_auth || false,
      include_in_escalations: elem.include_in_escalations || false,
      options: elem.multi_choices,
      topLevelField: elem.topLevelField,
      referencesCount:
        (elem?.understandings_count ?? 0) +
        (elem?.scripts_count ?? 0) +
        (elem?.contact_filters_count ?? 0),
    })
  )
}

// These are fields used to derive "Opted In", which _is_ surfaced on the frontend
// They should not be exposed to the frontend themselves.
const fieldsToExclude = new Set([
  'can_text',
  'dialog_id',
  'permanently_soft_stopped',
])

export const topLevelFieldToName = new Map([
  ['_id', 'ID'],
  ['crm_id', 'CRM ID'],
  ['enrollment_id', 'Enrollment ID'],
  ['name_first', 'First Name'],
  ['name_last', 'Last Name'],
  ['name_preferred', 'Preferred Name'],
  ['phone', 'Phone'],
  ['email', 'Email'],
  ['ab_group', 'A/B Group'],
  ['cohort_id', 'Cohort ID'],
  ['import_segment_labels', 'Import Segment Labels'],
  ['contact_labels', 'Contact Labels'],
  ['transport', 'Channel'],
  ['currently_opted_in', 'Opted In'],
  ['language', 'Language'],
  ['permitted_user', 'Permitted User'],
  ['test_user', 'Test User'],
  ['created', 'Created'],
  ['contacted', 'Contacted'],
  ['delivery_failure_count', 'Delivery Failure Count'],
  ['last_message_at', 'Last Message At'],
  ['last_message_body', 'Last Message Body'],
  ['last_incoming_message_at', 'Last Incoming Message At'],
  ['last_incoming_message_body', 'Last Incoming Message Body'],
  ['last_outgoing_message_at', 'Last Outgoing Message At'],
  ['last_outgoing_message_body', 'Last Outgoing Message Body'],
  ['received_scheduled_message_ids', 'Received Campaign IDs'],
  ['received_dialog_ids', 'Received Script IDs'],
  ['web_bot_tokens', 'Web Bots'],
  ['country_calling_code', 'Country Calling Code'],
  ['messaging_status_sms', 'Messaging Status (SMS)'],
  ['messaging_status_web_bot', 'Messaging Status (Web)'],
  ['messaging_status_slack', 'Messaging Status (Slack)'],
])

const reducer = (
  state: IPersonalizationContactAttrsState = INITIAL_PERSONALIZATION_STATE,
  action: IPersonalizationContactActions
): IPersonalizationContactAttrsState => {
  // TODO (Manan) - AH-4244 - When we call any of the updateAttribute actions, invalidate the allContactAttributes stored data
  switch (action.type) {
    case getType(getAllContactAttributesAction.request): {
      return {
        ...state,
        allContactAttributes: RefetchingOrLoading(state.allContactAttributes),
        contactAttributesRes: RefetchingOrLoading(state.contactAttributesRes),
      }
    }
    case getType(getAllContactAttributesAction.success): {
      return {
        ...state,
        allContactAttributes: Success(
          mapResponseDataToAttributes(action.payload)
        ),
        contactAttributesRes: Success(action.payload),
      }
    }
    case getType(getAllContactAttributesAction.failure): {
      return {
        ...state,
        allContactAttributes: Failure('Failed to fetch data'),
        contactAttributesRes: Failure('Failed to fetch data'),
      }
    }
    case getType(getAllScriptyContactAttributes.request): {
      return {
        ...state,
        allScriptyContactAttributes: RefetchingOrLoading(
          state.allScriptyContactAttributes
        ),
        scriptyContactAttributesRes: RefetchingOrLoading(
          state.scriptyContactAttributesRes
        ),
      }
    }
    case getType(getAllScriptyContactAttributes.success): {
      return {
        ...state,
        allScriptyContactAttributes: Success(
          mapResponseDataToAttributes(action.payload)
        ),
        scriptyContactAttributesRes: Success(action.payload),
      }
    }
    case getType(getAllScriptyContactAttributes.failure): {
      return {
        ...state,
        allScriptyContactAttributes: Failure('Failed to fetch data'),
        scriptyContactAttributesRes: Failure('Failed to fetch data'),
      }
    }
    case getType(clearAllContactAttributes): {
      return {
        ...state,
        allContactAttributes: undefined,
      }
    }
    case getType(listTopLevelContactFieldsAction.request): {
      return {
        ...state,
        topLevelContactFields: Loading(),
      }
    }
    case getType(listTopLevelContactFieldsAction.success): {
      return {
        ...state,
        topLevelContactFields: Success(
          Object.keys(action.payload)
            .filter(field => !fieldsToExclude.has(field))
            .map(field => ({
              readableName: topLevelFieldToName.get(field),
              field,
              type: action.payload[field],
            }))
        ),
      }
    }
    case getType(listTopLevelContactFieldsAction.failure): {
      return {
        ...state,
        topLevelContactFields: Failure('Failed to fetch contact fields.'),
      }
    }
    case getType(listSaveableTopLevelContactFields.request): {
      return {
        ...state,
        saveableTopLevelFieldsRes: Loading(),
      }
    }
    case getType(listSaveableTopLevelContactFields.success): {
      return {
        ...state,
        saveableTopLevelFieldsRes: Success(action.payload),
      }
    }
    case getType(listSaveableTopLevelContactFields.failure): {
      return {
        ...state,
        saveableTopLevelFieldsRes: Failure(
          'Failed to fetch saveable contact fields.'
        ),
      }
    }
    default: {
      return state
    }
  }
}

export default reducer
